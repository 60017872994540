import styled, { keyframes } from 'styled-components';
import { BaseTheme, Icon, Flex, H5 } from '@flexera/ui.component-library';

interface FooterBackgroundProps {
	showOverlay: boolean;
}

const fadeIn = keyframes`
0% { opacity: 0; display: none; }
100% { opacity: 1; display: block; }
`;

const fadeOut = keyframes`
0% {opacity: 1; display: block;  }
100% { opacity: 0; display: none; }
`;

export const FooterBackground = styled.div<FooterBackgroundProps>`
	grid-row: 1;
	background-color: ${BaseTheme.colors.gray60};
	mix-blend-mode: multiply;
	display: ${({ showOverlay }) => (showOverlay ? 'block' : 'none')};
	width: 100%;
	height: 100%;
	animation: ${({ showOverlay }) => (showOverlay ? fadeIn : fadeOut)} 1000ms
		forwards;
	top: 0;
	left: 0;
	position: absolute;
	cursor: pointer;
	z-index: 8;
`;

interface PlaylistProps {
	isOpen?: boolean;
	left?: number;
	right?: number;
	footerState?: string;
}

const slideUp = keyframes`
	0% {
		max-height: 56px;
		height: 56px;
		position: sticky;
  	}
  	100% {
		max-height: 100%;
		height: 100%;
		position: fixed;
  	}

`;

const slideDown = keyframes`
	0% { 
		max-height: 100%; 
		height: 100%;
		top: 185px;
		position: fixed;
	}
	100% {
		max-height: 56px; 
		height: 56px;
		top: unset;
		position: sticky;
	}
`;

const getAnimation = (footerState) => {
	if (footerState === 'open') {
		return slideUp;
	}
	if (footerState === 'close') {
		return slideDown;
	}

	return '';
};

export const FooterElement = styled.div`
	height: 185px;
	cursor: pointer;
`;

export const Footer = styled.footer<PlaylistProps>`
	font-family: ${BaseTheme.fonts.secondary};
	height: ${({ isOpen }) => (isOpen ? '100%' : '56px')};
	position: sticky;
	bottom: 0;
	z-index: 10;
	display: block;
	grid-template-rows: 185px auto;
	left: ${({ left }) => `${left}px`};
	right: ${({ right }) => `${right}px`};
	padding: 0;
	overflow-y: ${({ isOpen }) => (isOpen ? 'auto' : 'unset')};
	overflow-x: ${({ isOpen }) => (isOpen ? 'hidden' : 'unset')};
	animation: ${({ footerState }) => getAnimation(footerState)} 1000ms forwards;
	section.footerForeground {
		grid-row: 2;
		width: 100%;
		-ms-overflow-style: none;
		scrollbar-width: none;
		background-color: ${BaseTheme.colors.light};
		opacity: 1;
		grid-template-rows: repeat(3, min-content);
		grid-auto-flow: row;
		color: #f8f8f8;
		position: ${({ isOpen }) => (isOpen ? 'absolute' : 'unset')};
		top: 185px;

		&::-webkit-scrollbar {
			display: none;
		}

		& span.footerTitle {
			background: unset;
			border: unset;
			display: flex;
			align-items: center;
			text-transform: unset;
			font-family: inherit;
			fontweight: ${BaseTheme.fontWeights.semiBold};
			letterspacing: 0.4px;
			font-size: 18px;
			margin: ${BaseTheme.space.sm} ${BaseTheme.space.lg};
			cursor: pointer;
		}

		div.playlistTitle {
			grid-row: 1;
			grid-column: 1 / -1;
			width: 100%;
			margin: unset;
			display: flex;
			align-items: center;
			color: #f8f8f8;
			height: 56px;
			background-color: ${BaseTheme.colors.blue70};

			p {
				padding-right: 4px;
				margin: 0;
			}

			button {
				padding: unset;
				line-height: 18px;
				height: 24px;
			}

			button.closeButton {
				margin-left: auto;
			}
		}
	}
`;

export const FooterContent = styled.div`
	background: ${BaseTheme.colors.light};
	transition: all 1000ms ease-out;
`;

export const FooterActionIcon = styled(Flex)<PlaylistProps>`
	min-width: 46px;
	width: 46px;
	height: 46px;
	background: ${BaseTheme.colors.blue50};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	position: absolute;
	top: -${BaseTheme.space.md};
	right: 1.5rem;
	cursor: pointer;
	svg {
		width: 32px;
		height: 32px;
		margin: auto;
		g[fill='#000'],
		path[fill='#000'],
		path[fill='#000000'] {
			fill: ${BaseTheme.colors.light};
		}
	}
`;
export const IconWithHover = styled(Icon)`
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 44px;
	height: 44px;
	&:hover,
	&:active {
		box-shadow: 0px 4px 50px rgb(40 124 200 / 50%);
		border-radius: 50%;
	}
`;

export const CardTitle = styled(H5)`
	letter-spacing: 0.1px;
	color: ${BaseTheme.colors.blue70};
	margin-top: 0;
	margin-bottom: 0;
`;

export const Subtitle = styled.h6`
	font-family: ${BaseTheme.fonts.primary};
	font-size: ${BaseTheme.fontSizes.lg};
	line-height: ${BaseTheme.lineHeights.ls};
	letter-spacing: 0.2px;
	color: ${BaseTheme.colors.gray100};
	margin-top: ${BaseTheme.space.xxs};
	margin-bottom: ${BaseTheme.space.md};
	font-style: normal;
	font-weight: normal;
`;
