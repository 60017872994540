const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Account Management": {
        "msgid": "Account Management",
        "msgstr": [
          "Account Management"
        ]
      },
      "Group Management": {
        "msgid": "Group Management",
        "msgstr": [
          "Group Management"
        ]
      },
      "IP Access Control": {
        "msgid": "IP Access Control",
        "msgstr": [
          "IP Access Control"
        ]
      },
      "Identity Providers": {
        "msgid": "Identity Providers",
        "msgstr": [
          "Identity Providers"
        ]
      },
      "Invitations": {
        "msgid": "Invitations",
        "msgstr": [
          "Invitations"
        ]
      },
      "User Management": {
        "msgid": "User Management",
        "msgstr": [
          "User Management"
        ]
      }
    }
  }
};
export default json;
