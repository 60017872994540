const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Adjustment Program": {
        "msgid": "Adjustment Program",
        "msgstr": [
          "Programme d'ajustement"
        ]
      },
      "Billing Centers": {
        "msgid": "Billing Centers",
        "msgstr": [
          "Centres de facturation"
        ]
      },
      "Billing Configuration": {
        "msgid": "Billing Configuration",
        "msgstr": [
          "Configuration de la facturation"
        ]
      },
      "Budgets": {
        "msgid": "Budgets",
        "msgstr": [
          "Budgets"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Cloud Cost Anomalies": {
        "msgid": "Cloud Cost Anomalies",
        "msgstr": [
          "Anomalies des coûts du cloud"
        ]
      },
      "Cloud Migration Planning": {
        "msgid": "Cloud Migration Planning",
        "msgstr": [
          "Planification de la migration vers le cloud"
        ]
      },
      "Cost Planning": {
        "msgid": "Cost Planning",
        "msgstr": [
          "Planification des coûts"
        ]
      },
      "Create Recommendation Sets": {
        "msgid": "Create Recommendation Sets",
        "msgstr": [
          "Créer des ensembles de recommandations"
        ]
      },
      "Edit Recommendation Sets": {
        "msgid": "Edit Recommendation Sets",
        "msgstr": [
          "Modifier les ensembles de recommandations"
        ]
      },
      "Optimization": {
        "msgid": "Optimization",
        "msgstr": [
          "Optimisation"
        ]
      },
      "Recommendation Sets": {
        "msgid": "Recommendation Sets",
        "msgstr": [
          "Ensembles de recommandations"
        ]
      },
      "Reserved Instances": {
        "msgid": "Reserved Instances",
        "msgstr": [
          "Instances réservées"
        ]
      },
      "Rule-Based Dimensions": {
        "msgid": "Rule-Based Dimensions",
        "msgstr": [
          "Dimensions basées sur des règles"
        ]
      },
      "Savings Plans": {
        "msgid": "Savings Plans",
        "msgstr": [
          "Plans d'économies"
        ]
      },
      "Tabular View": {
        "msgid": "Tabular View",
        "msgstr": [
          "Vue tabulaire"
        ]
      },
      "Tag Dimensions": {
        "msgid": "Tag Dimensions",
        "msgstr": [
          "Dimensions des balises"
        ]
      },
      "Total Potential Savings": {
        "msgid": "Total Potential Savings",
        "msgstr": [
          "Total des économies potentielles"
        ]
      }
    }
  }
};
export default json;
