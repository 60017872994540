import React, { useState, useEffect } from 'react';
import {
	IconMdExpandMore,
	IconMdExpandLess,
	Icon,
	IconMdArrowTop,
	Flex,
	Button
} from '@flexera/ui.component-library';
import {
	getPreference,
	getSessionPreference,
	PreferenceKeys,
	setPreference
} from '@flexera/lib.preferences';
import { t } from 'ttag';
import {
	Footer,
	FooterBackground,
	FooterActionIcon,
	IconWithHover,
	FooterContent,
	FooterElement
} from './Styled';
import { VideoPlaylistCTATitle } from './videoPaylist/VideoPlaylist.lang';

import { Summary } from './summary';
import { Marketing } from './marketing/Marketing';
import { Insights } from './insights/Insights';
import { Testimonials } from './testimonials/Testimonials';
import { VideoPlaylist } from './videoPaylist/VideoPlaylist';

interface LandingFooterProps {
	boundingRect: ClientRect | null;
	styles?: React.CSSProperties | null;
}

export const LandingFooter = ({
	boundingRect,
	styles
}: LandingFooterProps): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const [footerState, setFoorterState] = useState('');
	const [showContent, setShowContent] = useState(false);
	const left = boundingRect ? boundingRect.left : 0;
	const footer = document.getElementById('landing-footer');

	useEffect(() => {
		const getSplashPreferences = async () => {
			let splashViewed = await getPreference(PreferenceKeys.splashViewed);
			splashViewed =
				splashViewed || getSessionPreference(PreferenceKeys.splashViewed);
			setIsOpen(!splashViewed);
			setShowContent(!splashViewed);
		};
		getSplashPreferences();
		setPreference({ [PreferenceKeys.splashViewed]: true });
	}, []);

	const handleToggleFooter = () => {
		setIsOpen(!isOpen);
		setFoorterState(isOpen ? 'close' : 'open');
		if (isOpen) {
			setTimeout(() => {
				setShowContent(false);
			}, 1000);
		} else {
			setShowContent(!isOpen);
		}
	};

	const handleCloseFooter = () => {
		setIsOpen(false);
		setFoorterState('close');
		setTimeout(() => {
			setShowContent(false);
		}, 1000);
	};

	const backToTop = () => {
		footer.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	const summaryOnClick = (
		area: 'tour-flexera' | 'testimonials' | 'video-playlist' | 'insights'
	) => {
		if (area === 'tour-flexera') {
			handleCloseFooter();
		} else {
			const areaElement = document.getElementById(area);
			const { offsetTop } = areaElement;
			footer.scrollTo({
				top: offsetTop,
				behavior: 'smooth'
			});
		}
	};

	return (
		<>
			<FooterBackground showOverlay={showContent} onClick={handleCloseFooter} />
			<Footer
				id={'landing-footer'}
				left={showContent ? left : 0}
				right={0}
				isOpen={isOpen}
				footerState={footerState}
				style={{ ...styles }}
			>
				{isOpen && <FooterElement onClick={handleCloseFooter} />}
				<section className={'footerForeground'}>
					<FooterActionIcon
						onClick={handleToggleFooter}
						isOpen={isOpen}
						tabIndex={0}
						id={'toogle-splash-footer'}
					>
						<IconWithHover
							color={'light'}
							src={showContent ? IconMdExpandMore : IconMdExpandLess}
						/>
					</FooterActionIcon>
					<div className={'playlistTitle'}>
						<span
							role={'button'}
							className={'footerTitle'}
							onKeyPress={handleToggleFooter}
							onClick={handleToggleFooter}
							tabIndex={0}
						>
							{VideoPlaylistCTATitle}
						</span>
					</div>
					{showContent && (
						<FooterContent>
							<Summary onClick={(area) => summaryOnClick(area)} />
							<Marketing />
							<Insights />
							<Testimonials />
							<VideoPlaylist left={`${left}px`} />
							<Flex justifyContent={'flex-end'} m={'32px 42px'}>
								<Button
									backToTop
									id={'back-to-top'}
									title={t`Back to top`}
									type={'button'}
									display={'flex'}
									alignItems={'center'}
									width={'fit-content'}
									icon={<Icon src={IconMdArrowTop} color={'light'} />}
									onClick={backToTop}
								>
									{t`Back to top`}
								</Button>
							</Flex>
						</FooterContent>
					)}
				</section>
			</Footer>
		</>
	);
};
