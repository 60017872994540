import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';

import { useEffect } from 'react';
import {
	UOMPowerBiDashboard,
	TechnologyIntelligenceReports,
	AIWrapper
} from './PlatformApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			// Platform (ITV, UOM Capability) page category
			{
				id: 'platform.bootstrap/ai',
				parentId: OrgsRouteId,
				path: '/platform/data-explorer',
				exact: true,
				component: AIWrapper
			},
			{
				id: `platform.bootstrap/powerbi-dashboard`,
				parentId: OrgsRouteId,
				path: '/platform/:pageId/:categoryId?',
				exact: true,
				component: TechnologyIntelligenceReports
			},
			// Custom Report (VIZ Capability)
			{
				id: `viz.bootstrap/powerbi-dashboard-custom-embed`,
				parentId: OrgsRouteId,
				path: `/viz/:pageId/:categoryId/:dashboard_id`,
				exact: true,
				component: UOMPowerBiDashboard
			}
		]);
	}, []);
}
