/* eslint-disable array-callback-return */
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex } from '@flexera/ui.component-library';
import { useCurrentOrg } from '@flexera/shell.orgs';
import {
	Permissions,
	Permission,
	PermissionSummary
} from '@flexera/lib.permissions';
import { Carousel, CarouselItemsWrapper } from '@flexera/shell.landing-footer';
import {
	GetStartedLink,
	GetStartedTitle,
	GetStartedParagraph,
	GetStartedItem,
	GetStartedIconWrapper,
	TitleWrapper
} from './Styled';
import { getStartedItems } from '../../data';
import { getIcon } from '../Icons';

interface GetStartedProps {
	permsEnabled: boolean;
	perms: PermissionSummary;
}

interface PermissionKey {
	[key: string]: Permission;
}

export const GetStartedCarousel: FC<GetStartedProps> = (
	props: GetStartedProps
) => {
	const { permsEnabled, perms } = props;
	const org = useCurrentOrg();
	const history = useHistory();

	return (
		<Carousel id={'get-started-carousel'}>
			<CarouselItemsWrapper>
				{getStartedItems.map((item) => {
					if (
						(item.hasPermissionCheck.length &&
							item.hasPermissionCheck.every((permissionGroup) => {
								if (Array.isArray(permissionGroup)) {
									return permissionGroup.some((permission) =>
										perms.get((Permissions as PermissionKey)[permission])
									);
								}
								return perms.get((Permissions as PermissionKey)[permissionGroup]);
							})) ||
						!item.hasPermissionCheck ||
						!permsEnabled
					) {
						return (
							<GetStartedLink
								title={item.title}
								id={item.title.toLowerCase().split(' ').join('-')}
								onClick={() => {
									setTimeout(() => history.push(`/orgs/${org.id}/${item.link}`));
								}}
								key={item.id}
							>
								<GetStartedItem>
									<Flex flexDirection={'column'}>
										<TitleWrapper>
											<GetStartedTitle>{item.title}</GetStartedTitle>
											<GetStartedIconWrapper>{getIcon(item.icon)}</GetStartedIconWrapper>
										</TitleWrapper>
										<GetStartedParagraph>{item.description}</GetStartedParagraph>
									</Flex>
								</GetStartedItem>
							</GetStartedLink>
						);
					}
				})}
			</CarouselItemsWrapper>
		</Carousel>
	);
};
