import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const TechnologyIntelligenceReports = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/platform.main');
		return { default: (await mod).TechnologyIntelligenceReports };
	}),
	'uom'
);

export const UOMPowerBiDashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/platform.main');
		return { default: (await mod).UOMPowerBiDashboard };
	}),
	'uom'
);

export const AIWrapper = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/platform.main');
		return { default: (await mod).AIWrapper };
	}),
	'uom'
);
