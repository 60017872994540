import { NavItemChild } from '@flexera/shell.navigation';
import { FavoriteItem } from '@flexera/shell.favorites';

export const generateNavigationMenuFromSavedFavorites = (
	navItems: NavItemChild[],
	favoriteStore: FavoriteItem[]
): NavItemChild[] => {
	let updatedFavoritedItems: NavItemChild[] = [];
	navItems.forEach((navItem) => {
		const childItems: NavItemChild[] = [];
		navItem.children.forEach((child) => {
			if (child.category) {
				const favoriteIds = new Set(favoriteStore.map((i) => i.itemId));

				const children = child.children
					.filter((c) => favoriteIds.has(c.id as string))
					.map((childMenu) => ({ ...childMenu, isInFavoriteMenu: true }));

				if (children && children.length > 0) {
					childItems.push({ ...child, children });
				}
			} else {
				const childItem = favoriteStore.find((f) => f.itemId === child.id);
				if (childItem) {
					childItems.push({ ...child, isInFavoriteMenu: true });
				}
			}
		});

		if (childItems.length > 0) {
			updatedFavoritedItems = [...updatedFavoritedItems, ...childItems];
		}
	});

	return updatedFavoritedItems;
};
