const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Begin Searching": {
        "msgid": "Begin Searching",
        "msgstr": [
          "Commencer la recherche"
        ]
      },
      "Cancel": {
        "msgid": "Cancel",
        "msgstr": [
          "Annuler"
        ]
      },
      "Clear your search": {
        "msgid": "Clear your search",
        "msgstr": [
          "Effacer votre recherche"
        ]
      },
      "Navigation Search": {
        "msgid": "Navigation Search",
        "msgstr": [
          "Recherche de navigation"
        ]
      },
      "No favorites to display.": {
        "msgid": "No favorites to display.",
        "msgstr": [
          "Aucun favori à afficher."
        ]
      },
      "No recent searches": {
        "msgid": "No recent searches",
        "msgstr": [
          "Aucune recherche récente"
        ]
      },
      "No results for this search": {
        "msgid": "No results for this search",
        "msgstr": [
          "Aucun résultat pour cette recherche"
        ]
      },
      "Press here to cancel the navigation search and see the navigation menu list.": {
        "msgid": "Press here to cancel the navigation search and see the navigation menu list.",
        "msgstr": [
          "Cliquez ici pour annuler la recherche de navigation et afficher la liste du menu de navigation."
        ]
      },
      "Press here to make ${ 0 } in favorite list.": {
        "msgid": "Press here to make ${ 0 } in favorite list.",
        "msgstr": [
          "Cliquez ici pour placer ${ 0 } dans la liste des favoris."
        ]
      },
      "Press here to remove ${ 0 } from favorite list.": {
        "msgid": "Press here to remove ${ 0 } from favorite list.",
        "msgstr": [
          "Cliquez ici pour supprimer ${ 0 } de la liste des favoris."
        ]
      },
      "Search": {
        "msgid": "Search",
        "msgstr": [
          "Recherche"
        ]
      },
      "Your recent search terms...": {
        "msgid": "Your recent search terms...",
        "msgstr": [
          "Vos termes de recherche récents..."
        ]
      },
      "get search results for recent search term:": {
        "msgid": "get search results for recent search term:",
        "msgstr": [
          "Obtenir des résultats pour un terme de recherche récent :"
        ]
      }
    }
  }
};
export default json;
