const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Add Dashboard": {
        "msgid": "Add Dashboard",
        "msgstr": [
          "Agregar panel"
        ]
      },
      "All Reports": {
        "msgid": "All Reports",
        "msgstr": [
          "Todos los informes"
        ]
      },
      "Connections": {
        "msgid": "Connections",
        "msgstr": [
          "Conexiones"
        ]
      },
      "Dashboard": {
        "msgid": "Dashboard",
        "msgstr": [
          "Panel"
        ]
      },
      "Data Explorer": {
        "msgid": "Data Explorer",
        "msgstr": [
          "Explorador de datos"
        ]
      },
      "Data Exports": {
        "msgid": "Data Exports",
        "msgstr": [
          "Exportación de datos"
        ]
      },
      "Data Mashup": {
        "msgid": "Data Mashup",
        "msgstr": [
          "Mashup de datos"
        ]
      },
      "Devices": {
        "msgid": "Devices",
        "msgstr": [
          "Equipos"
        ]
      },
      "Hardware Evidence": {
        "msgid": "Hardware Evidence",
        "msgstr": [
          "Evidencia de hardware"
        ]
      },
      "Reports": {
        "msgid": "Reports",
        "msgstr": [
          "Informes"
        ]
      },
      "Software Evidence": {
        "msgid": "Software Evidence",
        "msgstr": [
          "Evidencias de software"
        ]
      }
    }
  }
};
export default json;
