import React, { useState, useEffect, useRef } from 'react';
import { t } from 'ttag';

import { Icon } from '@flexera/ui.component-library';
import { FavoriteItem } from '@flexera/shell.favorites';
import { useOrgId } from '@flexera/shell.orgs';
import { useLocation } from 'react-router-dom';
import { NavItem, NavItemChild } from '@flexera/shell.navigation';
import { useAuthorizedPolicy } from '@flexera/shell.whitelabel';
import { useUserPreferences } from '@flexera/shell.user-preferences';

import { NavigationContext } from './navigationContext';

import { Menu } from './Menu/Menu';
import { Search } from './Search/Search';
import { NavigationWrapper, NavFooter } from './Navigation.styled';

import { NavExpandCollapse } from '../assets';
import { defaultFavoritesMenu, navId, navFooterId } from './constants';
import { getCleanItems, getFavoritesMenu } from '../utilities';

export const Navigation = ({ items }: NavItem[]) => {
	const location = useLocation();
	const isPBI = /^\/orgs\/[0-9]+\/(itv|vis|viz)\/report\/.+/.test(
		location.pathname
	);
	const [isExpanded, setIsExpanded] = useState(!isPBI);
	const [searchIsActive, setSearchIsActive] = useState(false);
	const [pinnedItem, setPinnedItem] = useState('');
	const [isPinned, setIsPinned] = useState(false);
	const [favoritesMenu, setFavoritesMenu] = useState<NavItemChild[]>(
		defaultFavoritesMenu
	);
	const [savedFavorites, setSavedFavorites] = useState<FavoriteItem[]>([]);
	const [resizerActive, setResizerActive] = useState(false);
	const [currentItems, setCurrentItems] = useState(null);
	const [hasFlyout, setHasFlyout] = useState(false);
	const navWrapperRef = useRef();
	const { isFetchingAuthPolicy } = useAuthorizedPolicy();
	const isSettings = /^\/settings\/.+/.test(location.pathname);
	const hasNoAccess = /^\/orgs\/.+\/no-access/.test(location.pathname);

	const orgId = useOrgId();
	const { getUserPreference, preferences } = useUserPreferences();

	const handleOutsideClick = (event: MouseEvent) => {
		if (!navWrapperRef.current.contains(event.target)) {
			setSearchIsActive(false);
		}
	};

	useEffect(() => {
		if (isPinned) return;
		setIsExpanded(!isPBI);
	}, [location.pathname]);

	useEffect(() => {
		const loadData = () => {
			const userPrefs = getUserPreference('favorites', +orgId);
			if (userPrefs?.value) {
				setSavedFavorites(JSON.parse(userPrefs?.value as string));
			}
		};
		if (orgId) loadData();
	}, [orgId, preferences]);

	/**
	 * This is listening to items, because we need to
	 * generate the favorites menu based on the most up-to-date
	 * items, not on initial load, as items on load includes placeholder
	 * 'loading' items. When the items resolves, we'll get the menu we expect.
	 * */
	useEffect(() => {
		getFavoritesMenu(items, savedFavorites, setFavoritesMenu);
		setCurrentItems(getCleanItems(items));
	}, [items]);

	useEffect(() => {
		if (savedFavorites) {
			getFavoritesMenu(items, savedFavorites, setFavoritesMenu);
		}
	}, [savedFavorites]);

	useEffect(() => {
		if (searchIsActive) {
			document.addEventListener('mouseup', handleOutsideClick);
		} else {
			document.removeEventListener('mouseup', handleOutsideClick);
		}
		return () => {
			document.removeEventListener('mouseup', handleOutsideClick);
		};
	}, [searchIsActive]);

	const handleExpandCollapse = () => {
		if (isPinned) {
			setPinnedItem('');
			setIsPinned(!isPinned);
		}
		setIsExpanded(!isExpanded);
	};

	const determineClassName = () => {
		if (isPinned) return 'pinned';
		if (!isExpanded) return 'collapsed';
		return '';
	};

	const mainNavMenu = <Menu items={currentItems} />;

	const canRender = isSettings || !isFetchingAuthPolicy;

	return (
		<NavigationContext.Provider
			value={{
				isExpanded,
				setIsExpanded,
				searchIsActive,
				setSearchIsActive,
				isPinned,
				setIsPinned,
				setPinnedItem,
				favoritesMenu,
				resizerActive,
				setResizerActive,
				navWrapperRef,
				orgId,
				savedFavorites,
				setSavedFavorites,
				hasFlyout,
				setHasFlyout
			}}
		>
			<NavigationWrapper
				id={navId}
				className={determineClassName()}
				selector={pinnedItem}
				ref={navWrapperRef}
				hasNoAccess={hasNoAccess}
			>
				{/* we need to wait until the whitelabel policy is done fetching before rendering the logo */}
				{canRender && !hasNoAccess && (
					<>
						<Search items={items} />
						{!searchIsActive && mainNavMenu}
						<NavFooter isExpanded={isExpanded} id={navFooterId}>
							<button
								type={'button'}
								title={
									searchIsActive
										? t`Expand is disabled when Search is active.`
										: t`Collapse or expand the menu`
								}
								onClick={() => handleExpandCollapse()}
								disabled={searchIsActive}
							>
								<Icon src={NavExpandCollapse} />
							</button>
						</NavFooter>
					</>
				)}
			</NavigationWrapper>
		</NavigationContext.Provider>
	);
};
