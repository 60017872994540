import { lazyLoaded } from '@flexera/lib.utilities';

export const ITVCustomDashboard = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).CustomDashboard };
});

export const ITVExports = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).Exports };
});

export const ITVExportsv3 = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).ExportsPagev3 };
});

export const ITVConnections = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).ConnectionsPage };
});

export const ITVDataMashup = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).DataMashup };
});

export const SoftwareEvidence = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).SoftwareEvidence };
});

export const SoftwareEvidenceDetail = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).SoftwareEvidenceDetail };
});

export const HardwareEvidence = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).HardwareEvidence };
});

export const PrivateCatalogEvidence = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).PrivateCatalogEvidence };
});

export const PrivateCatalogEvidenceFiltered = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).PrivateCatalogEvidenceFiltered };
});

export const Devices = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).Devices };
});

export const DeviceDetail = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).DeviceDetail };
});

export const PrivateCatalogReleases = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).PrivateCatalogReleases };
});

export const PrivateCatalogReleasesDetail = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).PrivateCatalogReleasesDetail };
});

export const PowerBIDashboard = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).PowerBIDashboard };
});

export const ContextualDataStore = lazyLoaded(async () => {
	const mod = import('@flexera/itv.main');
	return { default: (await mod).ContextualDataStorePage };
});
