const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Add Dashboard": {
        "msgid": "Add Dashboard",
        "msgstr": [
          "Add Dashboard"
        ]
      },
      "All Reports": {
        "msgid": "All Reports",
        "msgstr": [
          "All Reports"
        ]
      },
      "Connections": {
        "msgid": "Connections",
        "msgstr": [
          "Connections"
        ]
      },
      "Dashboard": {
        "msgid": "Dashboard",
        "msgstr": [
          "Dashboard"
        ]
      },
      "Data Explorer": {
        "msgid": "Data Explorer",
        "msgstr": [
          "Data Explorer"
        ]
      },
      "Data Exports": {
        "msgid": "Data Exports",
        "msgstr": [
          "Data Exports"
        ]
      },
      "Data Mashup": {
        "msgid": "Data Mashup",
        "msgstr": [
          "Data Mashup"
        ]
      },
      "Devices": {
        "msgid": "Devices",
        "msgstr": [
          "Devices"
        ]
      },
      "Hardware Evidence": {
        "msgid": "Hardware Evidence",
        "msgstr": [
          "Hardware Evidence"
        ]
      },
      "Reports": {
        "msgid": "Reports",
        "comments": {
          "extracted": "Only to be used as a menu item to point to Dashboards > All Reports page\nWill not be considered for URL matching for breadcrumb inference"
        },
        "msgstr": [
          "Reports"
        ]
      },
      "Software Evidence": {
        "msgid": "Software Evidence",
        "msgstr": [
          "Software Evidence"
        ]
      }
    }
  }
};
export default json;
