import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { MenuItemDef } from '@flexera/shell.navigation';
import {
	IconMdApplicationsEvidence,
	IconMdBusinessServices,
	IconMdSbomManagement,
	IconMdAutomation,
	IconMdCloud,
	IconMdDashboard,
	IconMdDataCollection,
	IconMdHardware,
	IconMdInventory,
	IconMdLicenses,
	IconFxConnector,
	IconMdRequests,
	IconMdSaas,
	IconMdTechnopedia,
	IconMdItVisibility,
	IconMdSettings,
	IconMdSvr,
	IconMdBusiness
} from '@flexera/ui.component-library';
import { OrganizationIcon, ProcurementIcon } from '../assets';
import {
	ADMIN,
	ADMIN__CLOUD,
	ADMIN__IDENTITY,
	ADMIN__IT_ASSETS,
	ADMIN__PLUGINS,
	ADMIN__SAAS,
	APPLICATIONS_AND_EVIDENCE,
	BUSINESS,
	CLOUD,
	CLOUD__COST,
	DASHBOARDS,
	DASHBOARDS__TI,
	DASHBOARDS__TIP,
	DATA_COLLECTION,
	DATA_COLLECTION__UDI,
	DATA_COLLECTION__UDI__STATUS,
	DATA_COLLECTION__SPEND__ANALYTICS,
	HARDWARE,
	INVENTORY,
	IT_REQUESTS,
	IT_REQUESTS__PORTAL,
	IT_REQUESTS__PORTAL_MANAGEMENT,
	IT_VISIBILITY,
	IT_VISIBILITY__TI,
	IT_VISIBILITY__CUSTOM_DASHBOARD,
	TECHNOPEDIA,
	LICENSES,
	LICENSES__LICENSES_BETA,
	ORGANIZATION,
	POLICIES,
	PROCUREMENT,
	PROCUREMENT__CONTRACTS_BETA,
	PROCUREMENT__PURCHASES_BETA,
	REPORTING,
	SAAS,
	SAAS__APPLICATIONS,
	SAAS__LICENSE_MANAGEMENT,
	SBOM,
	SVR,
	APPLICATIONS_AND_EVIDENCE__ITV_VISIBILITY,
	INVENTORY__ITV_VISIBILITY,
	CLOUD__COMMITMENTS,
	MSP
} from './ids';

export const items: MenuItemDef[] = [
	{
		label: t`Dashboards`,
		id: DASHBOARDS,
		description: t`All dashboards.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdDashboard,
		priority: 30
	},
	{
		id: DASHBOARDS__TI,
		parentId: DASHBOARDS,
		category: t`Technology Intelligence Reports`,
		helpHref: `https://docs.flexera.com/flexeraone/EN/WhatsNew/FeatureList/LandingPageTIP.htm`,
		label: t`Technology Intelligence Reports`,
		priority: 109,
		hasCategoryTopDivider: true
	},
	{
		id: DASHBOARDS__TIP,
		parentId: DASHBOARDS,
		category: t`Technology Intelligence Data Explorer`,
		helpHref: `https://docs.flexera.com/flexeraone/EN/WhatsNew/FeatureList/LandingPageTIP.htm`,
		label: t`Technology Intelligence Data Explorer`,
		priority: 110
	},
	{
		label: t`Business Services`,
		id: BUSINESS,
		description: t`Business services.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdBusinessServices,
		priority: 35
	},
	{
		label: t`SBOM Management`,
		id: SBOM,
		description: t`SBOM Management.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdSbomManagement,
		priority: 36
	},
	{
		label: t`IT Visibility`,
		id: IT_VISIBILITY,
		description: t`Discover and analyze your installed enterprise software.`,
		helpHref: `${helpDocBaseUrl}/ITVisibility/ITVisibilityGS.htm`,
		icon: IconMdItVisibility,
		priority: 40
	},
	{
		id: IT_VISIBILITY__TI,
		parentId: IT_VISIBILITY,
		category: t`Technology Intelligence Reports`,
		helpHref: `${helpDocBaseUrl}/ITVisibility/TIReports.htm`,
		label: t`Technology Intelligence Reports`,
		hasCategoryTopDivider: true,
		priority: 70
	},
	{
		label: t`Vulnerabilities`,
		id: SVR,
		description: t`Software Vulnerability Research.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdSvr,
		priority: 41
	},
	{
		label: t`Technopedia`,
		id: TECHNOPEDIA,
		description: t`Technopedia Dashboard.`,
		helpHref: `${helpDocBaseUrl}/ITVisibility/ITVisibilityGS.htm`,
		icon: IconMdTechnopedia,
		priority: 47
	},
	{
		id: IT_VISIBILITY__CUSTOM_DASHBOARD,
		parentId: IT_VISIBILITY,
		category: t`Custom Dashboards`,
		label: t`Custom Dashboards`,
		priority: 45
	},
	{
		label: t`Licenses`,
		id: LICENSES,
		description: t`Licenses.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdLicenses,
		priority: 50
	},
	{
		id: LICENSES__LICENSES_BETA,
		parentId: LICENSES,
		category: t`License (Beta)`,
		label: t`License (Beta)`,
		priority: 55
	},
	{
		label: t`Applications & Evidence`,
		id: APPLICATIONS_AND_EVIDENCE,
		description: t`Applications & Evidence.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdApplicationsEvidence,
		priority: 60
	},
	{
		label: t`Inventory`,
		id: INVENTORY,
		description: t`Inventory.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdInventory,
		priority: 70
	},
	{
		label: t`Hardware`,
		id: HARDWARE,
		description: t`Hardware.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdHardware,
		priority: 80
	},
	{
		label: t`SaaS`,
		id: SAAS,
		description: t`Manage your organization's SaaS spend.`,
		helpHref: `${helpDocBaseUrl}/SaaSManager/GettingStarted.htm`,
		icon: IconMdSaas,
		priority: 90
	},
	{
		label: t`Customers`,
		id: MSP,
		description: t`Manage multiple customer organization.`,
		icon: IconMdBusiness,
		priority: 95
	},
	{
		label: t`Cloud`,
		id: CLOUD,
		description: t`Understand your cloud costs and usage.`,
		helpHref: `${helpDocBaseUrl}/Optima/GettingStarted.htm`,
		icon: IconMdCloud,
		priority: 100
	},
	{
		label: t`Automation`,
		id: POLICIES,
		description: t`Automation.`,
		helpHref: `${helpDocBaseUrl}/Automation/AutomationGS.htm`,
		icon: IconMdAutomation,
		priority: 110
	},
	{
		label: t`Procurement`,
		id: PROCUREMENT,
		description: t`Procurement.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: ProcurementIcon,
		priority: 120
	},
	{
		id: PROCUREMENT__CONTRACTS_BETA,
		parentId: PROCUREMENT,
		category: t`Contracts (Beta)`,
		label: t`Contracts (Beta)`,
		priority: 125
	},
	{
		id: PROCUREMENT__PURCHASES_BETA,
		parentId: PROCUREMENT,
		category: t`Purchases & Vendors (Beta)`,
		label: t`Purchases & Vendors (Beta)`,
		priority: 126
	},
	{
		label: t`IT Asset Requests`,
		id: IT_REQUESTS,
		description: t`Automation for enterprise wide governance and self-service.`,
		helpHref: `${helpDocBaseUrl}/ITAssetRequests/GettingStarted.htm`,
		icon: IconMdRequests,
		priority: 130
	},
	{
		label: t`Organization`,
		id: ORGANIZATION,
		description: t`Organization.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: OrganizationIcon,
		priority: 140
	},
	{
		label: t`Reporting`,
		id: REPORTING,
		description: t`Reporting.`,
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdRequests,
		priority: 150
	},
	{
		label: t`Data Collection`,
		id: DATA_COLLECTION,
		description: 'DataCollection.',
		helpHref: `${helpDocBaseUrl}`,
		icon: IconMdDataCollection,
		priority: 160
	},
	{
		label: t`Administration`,
		id: ADMIN,
		description: t`Manage Flexera platform for your organization.`,
		helpHref: `${helpDocBaseUrl}/Administration/AdminGettingStarted.htm`,
		icon: IconMdSettings,
		priority: 170
	},
	{
		id: ADMIN__IDENTITY,
		parentId: ADMIN,
		priority: 10,
		category: t`Identity Management`,
		label: t`Identity Management`
	},
	{
		id: ADMIN__IT_ASSETS,
		parentId: ADMIN,
		priority: 20,
		category: t`IT Asset Management Settings`,
		label: t`IT Asset Management Settings`
	},
	{
		id: ADMIN__SAAS,
		parentId: ADMIN,
		priority: 30,
		category: t`SaaS Settings`,
		label: t`SaaS Settings`
	},
	{
		id: ADMIN__CLOUD,
		helpHref: `${helpDocBaseUrl}/Optima/GettingStarted.htm`,
		parentId: ADMIN,
		priority: 40,
		category: t`Cloud Settings`,
		label: t`Cloud Settings`
	},
	{
		id: ADMIN__PLUGINS,
		parentId: ADMIN,
		label: t`Plugins`,
		category: t`Plugins`,
		icon: IconFxConnector,
		priority: 50
	},
	{
		id: IT_REQUESTS__PORTAL_MANAGEMENT,
		parentId: IT_REQUESTS,
		category: t`Portal Management`,
		label: t`Portal Management`,
		priority: 10
	},
	{
		id: IT_REQUESTS__PORTAL,
		parentId: IT_REQUESTS,
		category: t`Request Portal`,
		label: t`Request Portal`,
		priority: 20
	},
	{
		id: CLOUD__COST,
		parentId: CLOUD,
		category: t`Cost Optimization`,
		label: t`Cost Optimization`,
		priority: 10
	},
	{
		id: CLOUD__COMMITMENTS,
		parentId: CLOUD,
		category: t`Commitments`,
		label: t`Commitments`,
		priority: 20
	},
	{
		id: SAAS__LICENSE_MANAGEMENT,
		parentId: SAAS,
		label: t`SaaS License Management`,
		category: t`SaaS License Management`,
		priority: 10
	},
	{
		id: SAAS__APPLICATIONS,
		parentId: SAAS,
		label: t`SaaS Applications`,
		category: t`SaaS Applications`,
		priority: 20
	},
	{
		id: DATA_COLLECTION__UDI,
		parentId: DATA_COLLECTION,
		label: t`IT Visibility Inventory Tasks`,
		category: t`IT Visibility Inventory Tasks`,
		helpHref: `${helpDocBaseUrl}/Beacons/GetStartBeacons.htm`,
		priority: 8
	},
	{
		id: DATA_COLLECTION__UDI__STATUS,
		parentId: DATA_COLLECTION,
		label: t`IT Visibility Inventory Status`,
		category: t`IT Visibility Inventory Status`,
		helpHref: `${helpDocBaseUrl}/Beacons/GetStartBeacons.htm`,
		priority: 9
	},
	{
		id: APPLICATIONS_AND_EVIDENCE__ITV_VISIBILITY,
		parentId: APPLICATIONS_AND_EVIDENCE,
		label: t`IT Visibility Software`,
		category: t`IT Visibility Software`,
		priority: 80
	},
	{
		id: INVENTORY__ITV_VISIBILITY,
		parentId: INVENTORY,
		label: t`IT Visibility Devices`,
		category: t`IT Visibility Devices`,
		priority: 88
	},
	{
		id: DATA_COLLECTION__SPEND__ANALYTICS,
		parentId: DATA_COLLECTION,
		label: t`Technology Spend`,
		category: t`Technology Spend`,
		priority: 10
	}
];
