const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Administration": {
        "msgid": "Administration",
        "msgstr": [
          "Administración"
        ]
      },
      "All dashboards.": {
        "msgid": "All dashboards.",
        "msgstr": [
          "Todos los paneles."
        ]
      },
      "Applications & Evidence": {
        "msgid": "Applications & Evidence",
        "msgstr": [
          "Aplicaciones y evidencias"
        ]
      },
      "Applications & Evidence.": {
        "msgid": "Applications & Evidence.",
        "msgstr": [
          "Aplicaciones y evidencias."
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automatización"
        ]
      },
      "Automation for enterprise wide governance and self-service.": {
        "msgid": "Automation for enterprise wide governance and self-service.",
        "msgstr": [
          "Automatización para el autoservicio y la gobernanza en toda la empresa."
        ]
      },
      "Automation.": {
        "msgid": "Automation.",
        "msgstr": [
          "Automatización."
        ]
      },
      "Business Services": {
        "msgid": "Business Services",
        "msgstr": [
          "Servicios empresariales"
        ]
      },
      "Business services.": {
        "msgid": "Business services.",
        "msgstr": [
          "Servicios empresariales."
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "En la nube"
        ]
      },
      "Cloud Settings": {
        "msgid": "Cloud Settings",
        "msgstr": [
          "Configuración de la nube"
        ]
      },
      "Commitments": {
        "msgid": "Commitments",
        "msgstr": [
          "Compromisos"
        ]
      },
      "Contracts (Beta)": {
        "msgid": "Contracts (Beta)",
        "msgstr": [
          "Contratos (beta)"
        ]
      },
      "Cost Optimization": {
        "msgid": "Cost Optimization",
        "msgstr": [
          "Optimización de costes"
        ]
      },
      "Custom Dashboards": {
        "msgid": "Custom Dashboards",
        "msgstr": [
          "Paneles personalizados"
        ]
      },
      "Dashboards": {
        "msgid": "Dashboards",
        "msgstr": [
          "Paneles"
        ]
      },
      "Data Collection": {
        "msgid": "Data Collection",
        "msgstr": [
          "Recopilación de datos"
        ]
      },
      "Discover and analyze your installed enterprise software.": {
        "msgid": "Discover and analyze your installed enterprise software.",
        "msgstr": [
          "Descubra y analice el software instalado en su empresa."
        ]
      },
      "Hardware": {
        "msgid": "Hardware",
        "msgstr": [
          "Hardware"
        ]
      },
      "Hardware.": {
        "msgid": "Hardware.",
        "msgstr": [
          "Hardware."
        ]
      },
      "IT Asset Management Settings": {
        "msgid": "IT Asset Management Settings",
        "msgstr": [
          "Configuración de la administración de activos de TI"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "Solicitudes de activos de TI"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "Visibilidad de TI"
        ]
      },
      "IT Visibility Devices": {
        "msgid": "IT Visibility Devices",
        "msgstr": [
          "Equipos de IT Visibility"
        ]
      },
      "IT Visibility Inventory Status": {
        "msgid": "IT Visibility Inventory Status",
        "msgstr": [
          "Estado de inventario de visibilidad de TI"
        ]
      },
      "IT Visibility Inventory Tasks": {
        "msgid": "IT Visibility Inventory Tasks",
        "msgstr": [
          "Tareas de inventario de visibilidad de TI"
        ]
      },
      "IT Visibility Software": {
        "msgid": "IT Visibility Software",
        "msgstr": [
          "Software IT Visibility"
        ]
      },
      "Identity Management": {
        "msgid": "Identity Management",
        "msgstr": [
          "Administración de identidades"
        ]
      },
      "Inventory": {
        "msgid": "Inventory",
        "msgstr": [
          "Inventario"
        ]
      },
      "Inventory.": {
        "msgid": "Inventory.",
        "msgstr": [
          "Inventario."
        ]
      },
      "License (Beta)": {
        "msgid": "License (Beta)",
        "msgstr": [
          "Licencia (beta)"
        ]
      },
      "Licenses": {
        "msgid": "Licenses",
        "msgstr": [
          "Licencias"
        ]
      },
      "Licenses.": {
        "msgid": "Licenses.",
        "msgstr": [
          "Licencias."
        ]
      },
      "Manage Flexera platform for your organization.": {
        "msgid": "Manage Flexera platform for your organization.",
        "msgstr": [
          "Administrar la plataforma Flexera para su organización. "
        ]
      },
      "Manage your organization's SaaS spend.": {
        "msgid": "Manage your organization's SaaS spend.",
        "msgstr": [
          "Administrar el gasto SaaS de su organización."
        ]
      },
      "Organization": {
        "msgid": "Organization",
        "msgstr": [
          "Organización"
        ]
      },
      "Organization.": {
        "msgid": "Organization.",
        "msgstr": [
          "Organización."
        ]
      },
      "Plugins": {
        "msgid": "Plugins",
        "msgstr": [
          "Complementos"
        ]
      },
      "Portal Management": {
        "msgid": "Portal Management",
        "msgstr": [
          "Administración del portal"
        ]
      },
      "Procurement": {
        "msgid": "Procurement",
        "msgstr": [
          "Compras"
        ]
      },
      "Procurement.": {
        "msgid": "Procurement.",
        "msgstr": [
          "Compras."
        ]
      },
      "Purchases & Vendors (Beta)": {
        "msgid": "Purchases & Vendors (Beta)",
        "msgstr": [
          "Compras y proveedores (beta)"
        ]
      },
      "Reporting": {
        "msgid": "Reporting",
        "msgstr": [
          "Informes"
        ]
      },
      "Reporting.": {
        "msgid": "Reporting.",
        "msgstr": [
          "Informes."
        ]
      },
      "Request Portal": {
        "msgid": "Request Portal",
        "msgstr": [
          "Portal de solicitud"
        ]
      },
      "SBOM Management": {
        "msgid": "SBOM Management",
        "msgstr": [
          "Administración de SBOM"
        ]
      },
      "SBOM Management.": {
        "msgid": "SBOM Management.",
        "msgstr": [
          "Administración de SBOM."
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "SaaS Applications": {
        "msgid": "SaaS Applications",
        "msgstr": [
          "Aplicaciones SaaS"
        ]
      },
      "SaaS License Management": {
        "msgid": "SaaS License Management",
        "msgstr": [
          "Administración de licencias SaaS"
        ]
      },
      "SaaS Settings": {
        "msgid": "SaaS Settings",
        "msgstr": [
          "Configuración de SaaS"
        ]
      },
      "Software Vulnerability Research.": {
        "msgid": "Software Vulnerability Research.",
        "msgstr": [
          "Investigación sobre vulnerabilidad de software."
        ]
      },
      "Technology Spend": {
        "msgid": "Technology Spend",
        "msgstr": [
          "Gasto en tecnología"
        ]
      },
      "Technopedia": {
        "msgid": "Technopedia",
        "msgstr": [
          "Technopedia"
        ]
      },
      "Technopedia Dashboard.": {
        "msgid": "Technopedia Dashboard.",
        "msgstr": [
          "Panel de Technopedia."
        ]
      },
      "Understand your cloud costs and usage.": {
        "msgid": "Understand your cloud costs and usage.",
        "msgstr": [
          "Comprenda los costes y el uso de la nube."
        ]
      },
      "Vulnerabilities": {
        "msgid": "Vulnerabilities",
        "msgstr": [
          "Vulnerabilidades"
        ]
      }
    }
  }
};
export default json;
