import { useEffect } from 'react';

import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	IT_VISIBILITY__TI,
	DASHBOARDS__TI,
	DASHBOARDS__TIP
} from '@flexera/shell.base-nav-tree';
import {
	loadingItem,
	MenuItem,
	useNavItems,
	LabelType
} from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { helpDocBaseUrl, useService } from '@flexera/lib.utilities';
import {
	useGraphQLAI,
	useHideTIPlatform,
	useHIFReports,
	useSaTechnologySpendPbiShow
} from '@flexera/flexera-one.feature-flags';
import { getCachedReportAccess } from '@flexera/lib.powerbi';
import {
	canDisplayCategoryTab,
	getHiddenCustomRoute,
	OOTB_DASHBOARD_PAGE_ID,
	OOTB_HIF_TI_PAGE_ID,
	OOTB_ITV_TI_PAGE_ID,
	OOTB_SA_TI_PAGE_ID,
	OOTB_TI_PAGE_CATEGORIES
} from '@flexera/platform.main';
import { t } from 'ttag';

import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	const hideTIPlatform = useHideTIPlatform();
	const hasHIFReports = useHIFReports();
	const saTechnologySpendPbiShow = useSaTechnologySpendPbiShow();
	const showGraphQLAI = useGraphQLAI();

	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const getCachedReportAccessService = useService(getCachedReportAccess);

	const [perms, loadingPerms] = usePermissionSummary(
		`/orgs/${org.id}`,
		Permissions.canViewHIFReports,
		Permissions.canViewSAReports,
		Permissions.hasSpendAnalytics,
		Permissions.hasHIF,
		Permissions.hasITVisibility,
		Permissions.canViewCustomReports,
		Permissions.canViewITVReports,
		Permissions.hasUnifiedObjectModel,
		Permissions.commonOrgOwner
	);

	useEffect(() => {
		if (!authenticated || !orgId || hideTIPlatform) return;

		if (org === OrgsLoading || loadingPerms) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (
			!(
				perms.get(Permissions.hasHIF) &&
				perms.get(Permissions.hasUnifiedObjectModel)
			) &&
			!(
				perms.get(Permissions.hasITVisibility) &&
				perms.get(Permissions.hasUnifiedObjectModel)
			) &&
			!perms.get(Permissions.hasSpendAnalytics)
		) {
			return;
		}

		const defaultRouteSet = [] as MenuItem[];
		const hasCustomReportAccess =
			perms.get(Permissions.canViewCustomReports) ||
			getCachedReportAccessService.data?.customReport;

		if (
			orgId &&
			getCachedReportAccessService.status === 'idle' &&
			perms.get(Permissions.hasUnifiedObjectModel) &&
			!perms.get(Permissions.canViewCustomReports)
		) {
			getCachedReportAccessService.load('viz', orgId);
			return;
		}

		const graphQLAIAccess =
			showGraphQLAI &&
			perms.get(Permissions.commonOrgOwner) &&
			perms.get(Permissions.hasUnifiedObjectModel);

		// Dashboards -> Technology Information Reports
		const hasHIFReportAccess =
			hasHIFReports &&
			perms.get(Permissions.hasUnifiedObjectModel) &&
			perms.get(Permissions.hasHIF) &&
			perms.get(Permissions.canViewHIFReports);
		const hasITVReportAccess =
			perms.get(Permissions.hasUnifiedObjectModel) &&
			perms.get(Permissions.hasITVisibility) &&
			perms.get(Permissions.canViewITVReports);
		const hasSAReportAccess =
			saTechnologySpendPbiShow &&
			perms.get(Permissions.hasSpendAnalytics) &&
			perms.get(Permissions.canViewSAReports);

		if (
			hasHIFReportAccess ||
			hasITVReportAccess ||
			hasCustomReportAccess ||
			hasSAReportAccess
		) {
			OOTB_TI_PAGE_CATEGORIES.filter((category) => {
				if (category.id === 'custom-reports') {
					return hasCustomReportAccess;
				}
				if (category.id === 'spend-and-cost') {
					return hasSAReportAccess || hasHIFReportAccess;
				}
				const canViewITVReports = canDisplayCategoryTab(
					category.id,
					OOTB_ITV_TI_PAGE_ID,
					hasITVReportAccess
				);

				const canViewHIFReports = canDisplayCategoryTab(
					category.id,
					OOTB_HIF_TI_PAGE_ID,
					hasHIFReportAccess
				);

				const canViewSAReports = canDisplayCategoryTab(
					category.id,
					OOTB_SA_TI_PAGE_ID,
					hasSAReportAccess
				);

				return canViewITVReports || canViewHIFReports || canViewSAReports;
			}).forEach(({ id, label }, index) => {
				const priority = index + 1;
				defaultRouteSet.push({
					id: `${ids.OOTB_DASHBOARDS_TI_PAGE}-${id}`,
					parentId: DASHBOARDS__TI,
					path: `/orgs/${orgId}/platform/${OOTB_DASHBOARD_PAGE_ID}/${id}`,
					urlMatch: new RegExp(
						`^\\/orgs\\/\\d+\\/platform\\/${OOTB_DASHBOARD_PAGE_ID}\\/${id}(\\/|$|\\?)`
					),
					label,
					priority
				});
			});
			if (showGraphQLAI) {
				defaultRouteSet.push({
					id: `${ids.OOTB_DASHBOARDS_TI_PAGE}-/platform/data-explorer`,
					parentId: DASHBOARDS__TIP,
					path: `/orgs/${orgId}/platform/data-explorer`,
					urlMatch: new RegExp(
						`^\\/orgs\\/\\d+\\/platform/data-explorer(\\/|$|\\?)`
					),
					label: t`GraphQL Query Generator`,
					priority: 1,
					labelType: LabelType.NEW
				});
			}
		}

		// IT Visibility -> Technology Information Reports
		if (hasITVReportAccess) {
			OOTB_TI_PAGE_CATEGORIES.filter((category) => {
				if (category.id === 'custom-reports') {
					return hasCustomReportAccess;
				}

				if (category.id === 'spend-and-cost') {
					return hasSAReportAccess;
				}
				const canViewITVReports = canDisplayCategoryTab(
					category.id,
					OOTB_ITV_TI_PAGE_ID,
					hasITVReportAccess
				);

				const canViewSAReports = canDisplayCategoryTab(
					category.id,
					OOTB_SA_TI_PAGE_ID,
					hasSAReportAccess
				);

				return canViewITVReports || canViewSAReports;
			}).forEach(({ id, label }, index) => {
				const priority = index + 1;
				defaultRouteSet.push({
					id: `${ids.OOTB_ITV_TI_PAGE}-${id}`,
					parentId: IT_VISIBILITY__TI,
					path: `/orgs/${orgId}/platform/${OOTB_ITV_TI_PAGE_ID}/${id}`,
					urlMatch: new RegExp(
						`^\\/orgs\\/\\d+\\/platform\\/${OOTB_ITV_TI_PAGE_ID}\\/${id}(\\/|$|\\?)`
					),
					label,
					priority
				});
			});
		}

		if (hasCustomReportAccess) {
			const hiddenRouteDefaultDetails = {
				routeId: ids.VIEW,
				helpHref: `${helpDocBaseUrl}/ITVisibility/CustomReports.htm`,
				orgId
			};

			// Dashboards -> Technology Information Custom Report
			const hiddenDashboardRoute = getHiddenCustomRoute({
				...hiddenRouteDefaultDetails,
				parentId: DASHBOARDS__TI,
				pageId: OOTB_DASHBOARD_PAGE_ID
			});
			defaultRouteSet.push(hiddenDashboardRoute);

			// IT Visibility -> Technology Information Custom Report
			if (hasITVReportAccess) {
				const hiddenITVRoute = getHiddenCustomRoute({
					...hiddenRouteDefaultDetails,
					parentId: IT_VISIBILITY__TI,
					pageId: OOTB_ITV_TI_PAGE_ID
				});
				defaultRouteSet.push(hiddenITVRoute);
			}
		}

		return addItems(defaultRouteSet);
	}, [
		org,
		orgId,
		authenticated,
		loadingPerms,
		perms,
		hideTIPlatform,
		getCachedReportAccessService.status,
		showGraphQLAI
	]);
}
