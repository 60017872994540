const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Adjustment Program": {
        "msgid": "Adjustment Program",
        "msgstr": [
          "Programa de ajuste"
        ]
      },
      "Billing Centers": {
        "msgid": "Billing Centers",
        "msgstr": [
          "Centros de facturación"
        ]
      },
      "Billing Configuration": {
        "msgid": "Billing Configuration",
        "msgstr": [
          "Configuración de facturación"
        ]
      },
      "Budgets": {
        "msgid": "Budgets",
        "msgstr": [
          "Presupuestos"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "En la nube"
        ]
      },
      "Cloud Cost Anomalies": {
        "msgid": "Cloud Cost Anomalies",
        "msgstr": [
          "Anomalías en el coste de la nube"
        ]
      },
      "Cloud Migration Planning": {
        "msgid": "Cloud Migration Planning",
        "msgstr": [
          "Planificación de migración a la nube"
        ]
      },
      "Cost Planning": {
        "msgid": "Cost Planning",
        "msgstr": [
          "Planificación de costes"
        ]
      },
      "Create Recommendation Sets": {
        "msgid": "Create Recommendation Sets",
        "msgstr": [
          "Crear conjuntos de recomendaciones"
        ]
      },
      "Edit Recommendation Sets": {
        "msgid": "Edit Recommendation Sets",
        "msgstr": [
          "Editar conjuntos de recomendaciones"
        ]
      },
      "Optimization": {
        "msgid": "Optimization",
        "msgstr": [
          "Optimización"
        ]
      },
      "Recommendation Sets": {
        "msgid": "Recommendation Sets",
        "msgstr": [
          "Conjuntos de recomendaciones"
        ]
      },
      "Reserved Instances": {
        "msgid": "Reserved Instances",
        "msgstr": [
          "Instancias reservadas"
        ]
      },
      "Rule-Based Dimensions": {
        "msgid": "Rule-Based Dimensions",
        "msgstr": [
          "Dimensiones basadas en reglas"
        ]
      },
      "Savings Plans": {
        "msgid": "Savings Plans",
        "msgstr": [
          "Planes de ahorro"
        ]
      },
      "Tabular View": {
        "msgid": "Tabular View",
        "msgstr": [
          "Vista tabular"
        ]
      },
      "Tag Dimensions": {
        "msgid": "Tag Dimensions",
        "msgstr": [
          "Dimensiones de la etiqueta"
        ]
      },
      "Total Potential Savings": {
        "msgid": "Total Potential Savings",
        "msgstr": [
          "Ahorro potencial total"
        ]
      }
    }
  }
};
export default json;
