const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Begin Searching": {
        "msgid": "Begin Searching",
        "msgstr": [
          "Iniciar búsqueda"
        ]
      },
      "Cancel": {
        "msgid": "Cancel",
        "msgstr": [
          "Cancelar"
        ]
      },
      "Clear your search": {
        "msgid": "Clear your search",
        "msgstr": [
          "Borre su búsqueda"
        ]
      },
      "Navigation Search": {
        "msgid": "Navigation Search",
        "msgstr": [
          "Búsqueda de navegación"
        ]
      },
      "No favorites to display.": {
        "msgid": "No favorites to display.",
        "msgstr": [
          "No existen favoritos."
        ]
      },
      "No recent searches": {
        "msgid": "No recent searches",
        "msgstr": [
          "No hay búsquedas recientes"
        ]
      },
      "No results for this search": {
        "msgid": "No results for this search",
        "msgstr": [
          "No hay resultados para esta búsqueda"
        ]
      },
      "Press here to cancel the navigation search and see the navigation menu list.": {
        "msgid": "Press here to cancel the navigation search and see the navigation menu list.",
        "msgstr": [
          "Haga clic aquí para cancelar la búsqueda de navegación y ver la lista de menús de navegación."
        ]
      },
      "Press here to make ${ 0 } in favorite list.": {
        "msgid": "Press here to make ${ 0 } in favorite list.",
        "msgstr": [
          "Haga clic aquí para incluir ${ 0 } en la lista de favoritos.\n"
        ]
      },
      "Press here to remove ${ 0 } from favorite list.": {
        "msgid": "Press here to remove ${ 0 } from favorite list.",
        "msgstr": [
          "Haga clic aquí para eliminar ${ 0 } de la lista de favoritos."
        ]
      },
      "Search": {
        "msgid": "Search",
        "msgstr": [
          "Buscar"
        ]
      },
      "Your recent search terms...": {
        "msgid": "Your recent search terms...",
        "msgstr": [
          "Últimos términos de búsqueda..."
        ]
      },
      "get search results for recent search term:": {
        "msgid": "get search results for recent search term:",
        "msgstr": [
          "obtener resultados de búsqueda para un término de búsqueda reciente:"
        ]
      }
    }
  }
};
export default json;
