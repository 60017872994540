import { withCapabilities } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const SnowSplash = withCapabilities(
	lazyLoaded(async () => {
		const mod = import('@flexera/snow.main');
		return { default: (await mod).SnowSplash };
	}),
	[
		'snow-atlas-saas',
		'snow-atlas-sam',
		'snow-atlas-containers',
		'snow-atlas-partner',
		'snow-atlas-clv'
	]
);
