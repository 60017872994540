import { Environment, environment, RegionName } from '@flexera/lib.environment';
import { initialize } from '@sumologic/opentelemetry-rum';

const serviceName = 'flexera-one-ui-platform';

const traceEndpoint =
	'https://rum-collectors.us1.sumologic.com/receiver/v1/rum/ZaVnC4dhaV0bvhArw3CCfvsuuUlGDCUzDTAxD9_k0PV-wWiKkybCFkjHODH2X8EnohqKFA2906-EiyePNv_Vy_FOlmlRsWoBhzxXfPKWW9dVbvfGswz74A==';

const collectionSourceUrl = {
	[Environment.Local]: undefined,
	[Environment.Dev]: undefined,
	[Environment.Staging]: traceEndpoint,
	[Environment.Production]: traceEndpoint,
	[Environment.EUProduction]: traceEndpoint,
	[Environment.AUProduction]: traceEndpoint
}[environment];

const envServiceName = {
	[Environment.Local]: undefined,
	[Environment.Dev]: undefined,
	[Environment.Staging]: `${serviceName}-staging`,
	[Environment.Production]: `${serviceName}-nam-production`,
	[Environment.EUProduction]: `${serviceName}-eu-production`,
	[Environment.AUProduction]: `${serviceName}-apac-production`
}[environment];

export const loadSumologic = () => {
	if (collectionSourceUrl) {
		initialize({
			applicationName: 'flexera-one-ui',
			collectionSourceUrl,
			collectErrors: true,
			deploymentEnvironment: RegionName,
			serviceName,
			getOverriddenServiceName: () => {
				return envServiceName;
			}
			// propagateTraceHeaderCorsUrls: [
			// 	new RegExp('https://api.flexera(test)*.(com|eu|au|io)/.*')
			// ]
		});
	}
};
