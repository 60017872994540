const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Clear the branch and go back to the production build?": {
        "msgid": "Clear the branch and go back to the production build?",
        "msgstr": [
          "Effacer l'arborescence et revenir à la version de production ?"
        ]
      },
      "Error...": {
        "msgid": "Error...",
        "msgstr": [
          "Erreur..."
        ]
      },
      "Get help in Community": {
        "msgid": "Get help in Community",
        "msgstr": [
          "Obtenir de l'aide dans la communauté"
        ]
      },
      "Loading...": {
        "msgid": "Loading...",
        "msgstr": [
          "Chargement en cours..."
        ]
      },
      "Log out": {
        "msgid": "Log out",
        "msgstr": [
          "Déconnexion"
        ]
      },
      "Press help icon to show all options.": {
        "msgid": "Press help icon to show all options.",
        "msgstr": [
          "Appuyez sur l'icône d'aide pour afficher toutes les options."
        ]
      },
      "Press here to Get help in Community and discuss this feature on the Flexera Community.": {
        "msgid": "Press here to Get help in Community and discuss this feature on the Flexera Community.",
        "msgstr": [
          "Cliquez ici pour obtenir de l'aide dans la communauté et discuter de cette fonctionnalité dans la communauté Flexera."
        ]
      },
      "Press here to view documentation with detailed feature on docs.flexera.com.": {
        "msgid": "Press here to view documentation with detailed feature on docs.flexera.com.",
        "msgstr": [
          "Cliquez ici pour consulter la documentation détaillée sur docs.flexera.com."
        ]
      },
      "Privacy Policy": {
        "msgid": "Privacy Policy",
        "msgstr": [
          "Politique de confidentialité"
        ]
      },
      "Profile details": {
        "msgid": "Profile details",
        "msgstr": [
          "Détails du profil"
        ]
      },
      "Select an Organization": {
        "msgid": "Select an Organization",
        "msgstr": [
          "Sélectionner une organisation"
        ]
      },
      "Selected Organization ${ 0 }": {
        "msgid": "Selected Organization ${ 0 }",
        "msgstr": [
          "Organisation sélectionnée { 0 }"
        ]
      },
      "User Settings": {
        "msgid": "User Settings",
        "msgstr": [
          "Paramètres de l'utilisateur"
        ]
      },
      "View Documentation": {
        "msgid": "View Documentation",
        "msgstr": [
          "Voir la documentation"
        ]
      },
      "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?": {
        "msgid": "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?",
        "msgstr": [
          "Vous exécutez une version à étiquette blanche de Flexera One correspondant à la portée du partenaire ${ 0 }. Voulez-vous effacer ce paramètre et revenir à la version de production principale ?"
        ]
      }
    }
  }
};
export default json;
