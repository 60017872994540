const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Administration": {
        "msgid": "Administration",
        "msgstr": [
          "Administration"
        ]
      },
      "All dashboards.": {
        "msgid": "All dashboards.",
        "msgstr": [
          "All dashboards."
        ]
      },
      "Applications & Evidence": {
        "msgid": "Applications & Evidence",
        "msgstr": [
          "Applications & Evidence"
        ]
      },
      "Applications & Evidence.": {
        "msgid": "Applications & Evidence.",
        "msgstr": [
          "Applications & Evidence."
        ]
      },
      "Automation": {
        "msgid": "Automation",
        "msgstr": [
          "Automation"
        ]
      },
      "Automation for enterprise wide governance and self-service.": {
        "msgid": "Automation for enterprise wide governance and self-service.",
        "msgstr": [
          "Automation for enterprise wide governance and self-service."
        ]
      },
      "Automation.": {
        "msgid": "Automation.",
        "msgstr": [
          "Automation."
        ]
      },
      "Business Services": {
        "msgid": "Business Services",
        "msgstr": [
          "Business Services"
        ]
      },
      "Business services.": {
        "msgid": "Business services.",
        "msgstr": [
          "Business services."
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Cloud Settings": {
        "msgid": "Cloud Settings",
        "msgstr": [
          "Cloud Settings"
        ]
      },
      "Commitments": {
        "msgid": "Commitments",
        "msgstr": [
          "Commitments"
        ]
      },
      "Contracts (Beta)": {
        "msgid": "Contracts (Beta)",
        "msgstr": [
          "Contracts (Beta)"
        ]
      },
      "Cost Optimization": {
        "msgid": "Cost Optimization",
        "msgstr": [
          "Cost Optimization"
        ]
      },
      "Custom Dashboards": {
        "msgid": "Custom Dashboards",
        "msgstr": [
          "Custom Dashboards"
        ]
      },
      "Dashboards": {
        "msgid": "Dashboards",
        "msgstr": [
          "Dashboards"
        ]
      },
      "Data Collection": {
        "msgid": "Data Collection",
        "msgstr": [
          "Data Collection"
        ]
      },
      "Discover and analyze your installed enterprise software.": {
        "msgid": "Discover and analyze your installed enterprise software.",
        "msgstr": [
          "Discover and analyze your installed enterprise software."
        ]
      },
      "Hardware": {
        "msgid": "Hardware",
        "msgstr": [
          "Hardware"
        ]
      },
      "Hardware.": {
        "msgid": "Hardware.",
        "msgstr": [
          "Hardware."
        ]
      },
      "IT Asset Management Settings": {
        "msgid": "IT Asset Management Settings",
        "msgstr": [
          "IT Asset Management Settings"
        ]
      },
      "IT Asset Requests": {
        "msgid": "IT Asset Requests",
        "msgstr": [
          "IT Asset Requests"
        ]
      },
      "IT Visibility": {
        "msgid": "IT Visibility",
        "msgstr": [
          "IT Visibility"
        ]
      },
      "IT Visibility Devices": {
        "msgid": "IT Visibility Devices",
        "msgstr": [
          "IT Visibility Devices"
        ]
      },
      "IT Visibility Inventory Status": {
        "msgid": "IT Visibility Inventory Status",
        "msgstr": [
          "IT Visibility Inventory Status"
        ]
      },
      "IT Visibility Inventory Tasks": {
        "msgid": "IT Visibility Inventory Tasks",
        "msgstr": [
          "IT Visibility Inventory Tasks"
        ]
      },
      "IT Visibility Software": {
        "msgid": "IT Visibility Software",
        "msgstr": [
          "IT Visibility Software"
        ]
      },
      "Identity Management": {
        "msgid": "Identity Management",
        "msgstr": [
          "Identity Management"
        ]
      },
      "Inventory": {
        "msgid": "Inventory",
        "msgstr": [
          "Inventory"
        ]
      },
      "Inventory.": {
        "msgid": "Inventory.",
        "msgstr": [
          "Inventory."
        ]
      },
      "License (Beta)": {
        "msgid": "License (Beta)",
        "msgstr": [
          "License (Beta)"
        ]
      },
      "Licenses": {
        "msgid": "Licenses",
        "msgstr": [
          "Licenses"
        ]
      },
      "Licenses.": {
        "msgid": "Licenses.",
        "msgstr": [
          "Licenses."
        ]
      },
      "Manage Flexera platform for your organization.": {
        "msgid": "Manage Flexera platform for your organization.",
        "msgstr": [
          "Manage Flexera platform for your organization."
        ]
      },
      "Manage your organization's SaaS spend.": {
        "msgid": "Manage your organization's SaaS spend.",
        "msgstr": [
          "Manage your organization's SaaS spend."
        ]
      },
      "Organization": {
        "msgid": "Organization",
        "msgstr": [
          "Organization"
        ]
      },
      "Organization.": {
        "msgid": "Organization.",
        "msgstr": [
          "Organization."
        ]
      },
      "Plugins": {
        "msgid": "Plugins",
        "msgstr": [
          "Plugins"
        ]
      },
      "Portal Management": {
        "msgid": "Portal Management",
        "msgstr": [
          "Portal Management"
        ]
      },
      "Procurement": {
        "msgid": "Procurement",
        "msgstr": [
          "Procurement"
        ]
      },
      "Procurement.": {
        "msgid": "Procurement.",
        "msgstr": [
          "Procurement."
        ]
      },
      "Purchases & Vendors (Beta)": {
        "msgid": "Purchases & Vendors (Beta)",
        "msgstr": [
          "Purchases & Vendors (Beta)"
        ]
      },
      "Reporting": {
        "msgid": "Reporting",
        "msgstr": [
          "Reporting"
        ]
      },
      "Reporting.": {
        "msgid": "Reporting.",
        "msgstr": [
          "Reporting."
        ]
      },
      "Request Portal": {
        "msgid": "Request Portal",
        "msgstr": [
          "Request Portal"
        ]
      },
      "SBOM Management": {
        "msgid": "SBOM Management",
        "msgstr": [
          "SBOM Management"
        ]
      },
      "SBOM Management.": {
        "msgid": "SBOM Management.",
        "msgstr": [
          "SBOM Management."
        ]
      },
      "SaaS": {
        "msgid": "SaaS",
        "msgstr": [
          "SaaS"
        ]
      },
      "SaaS Applications": {
        "msgid": "SaaS Applications",
        "msgstr": [
          "SaaS Applications"
        ]
      },
      "SaaS License Management": {
        "msgid": "SaaS License Management",
        "msgstr": [
          "SaaS License Management"
        ]
      },
      "SaaS Settings": {
        "msgid": "SaaS Settings",
        "msgstr": [
          "SaaS Settings"
        ]
      },
      "Software Vulnerability Research.": {
        "msgid": "Software Vulnerability Research.",
        "msgstr": [
          "Software Vulnerability Research."
        ]
      },
      "Technology Spend": {
        "msgid": "Technology Spend",
        "msgstr": [
          "Technology Spend"
        ]
      },
      "Technopedia": {
        "msgid": "Technopedia",
        "msgstr": [
          "Technopedia"
        ]
      },
      "Technopedia Dashboard.": {
        "msgid": "Technopedia Dashboard.",
        "msgstr": [
          "Technopedia Dashboard."
        ]
      },
      "Understand your cloud costs and usage.": {
        "msgid": "Understand your cloud costs and usage.",
        "msgstr": [
          "Understand your cloud costs and usage."
        ]
      },
      "Vulnerabilities": {
        "msgid": "Vulnerabilities",
        "msgstr": [
          "Vulnerabilities"
        ]
      }
    }
  }
};
export default json;
