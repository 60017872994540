export const WORKSPACES = '8ad68056-c9fd-41c0-b796-7121b358616d';
export const HYBRID_ITAM_FINOPS = '761c0fad-6174-484f-ad33-8c3ccf045e06';
export const SPEND_ANALYTICS = '3a5f28f8-25c4-4f47-b9e0-39625824c9c5';
export const DASHBOARDS = '76eb2d8c-5952-40be-a5bd-5ce46d4c7d48';
export const DASHBOARDS__TI = `40c6-501e-9ed5fbcd-ea61cac737de-8e8e`;
export const DASHBOARDS__TIP = `a1b2-3c4d-5e6f-7890abcd-ef12gh34ijkl-mn56`;
export const BUSINESS = '11ab2c3d-4567-89ef-g0hi-1gk23l4m5n67';
export const SBOM = '95a2a1e0-6d2a-42a6-859b-f83253f4bdfe';
export const SVR = '59fe5c96-ac62-4bce-8ae7-6760f2694c2f';
export const IT_VISIBILITY = '9ed5fbcd-501e-40c6-8e8e-ea61cac737de';
export const IT_VISIBILITY__TI = `ea61cac737de-8e8e-40c6-501e-9ed5fbcd`;
export const UOM = '83915e2a-b5ff-493e-a1ac-a896959f5bbc';
export const TECHNOPEDIA = '1wd5fbcd-505e-40c6-8e8f-va65cac717dg';
export const IT_VISIBILITY__CUSTOM_DASHBOARD =
	'48e723bb-6752-485d-8f7a-c24a669977e2';
export const LICENSES = 'ae85a087-b90e-4f9d-b09f-db83520bc987';
export const LICENSES__LICENSES_BETA = 'b3894811-decf-4f35-8930-4df7bde92a71';
export const APPLICATIONS_AND_EVIDENCE = 'a563edc6-04d1-45da-a5f3-3735a61f667c';
export const APPLICATIONS_AND_EVIDENCE__ITV_VISIBILITY =
	'2ba94702-c7f3-4486-bfa1-810707b4673c';
export const INVENTORY__ITV_VISIBILITY = 'a80eb30d-3990-4a5c-9f49-6a24dcb9afa9';
export const INVENTORY = '9db16b90-636e-4d74-a9f7-6b8db7ac5adb';
export const HARDWARE = '5c10971e-b413-4fe9-8ba4-a095ee0fe6a7';
export const POLICIES = '37565b22-26f3-4edc-bdfa-adf630df4b59';
export const PROCUREMENT = '5ca184ef-783c-4823-b924-a731228be8d3';
export const PROCUREMENT__CONTRACTS_BETA =
	'7b041e40-db5d-4acc-a497-f36b6543b4e2';
export const PROCUREMENT__PURCHASES_BETA =
	'295700d4-f54b-43e1-8235-044322ef8dcf';

export const ORGANIZATION = '6bd13be2-9831-4b56-b54d-d4da18d381a1';
export const REPORTING = 'c247ffff-c141-450f-a9d9-7f1b3cc2f3cb';

// #region Data Collection
export const DATA_COLLECTION = '89ac2b62-e2c2-4674-aeca-cb803f6bcb9f';
export const DATA_COLLECTION__UDI = 'c26e8854-25e1-4199-87ab-d473f4d630d3';
export const DATA_COLLECTION__UDI__STATUS =
	'3e68a341-11ad-4e23-b6d9-738becf3e40e';
export const DATA_COLLECTION__SPEND__ANALYTICS =
	'940fa6ce-aa69-4935-8b9d-9472994687e3';
// #endregion

// #region SaaS
export const SAAS = '6fc58774-c7fa-415a-9ea3-28356184c250';
export const SAAS__LICENSE_MANAGEMENT = 'b6744647-39fe-4c59-81b8-67cf4f14b04c';
export const SAAS__APPLICATIONS = 'b7163d11-acb5-4c4a-a199-31091d451b76';
// #endregion

// #region MSP
export const MSP = '8c992def-a8f5-4f51-8cd3-c9d60f562090';
// #endregion

// #region Cloud
export const CLOUD = 'aa36636d-2e9c-45a6-b26e-f05c24144e84';
export const CLOUD__COST = 'a50fea81-38e6-4e12-8d30-bcbd036110de';
export const CLOUD__COMMITMENTS = 'a50fea81-38e6-4e12-8d30-bcbd036110da';
// #endregion

// #region Admin
export const ADMIN = '24a227c7-5a55-4bf2-bbfc-91fff36d3176';
export const ADMIN__IDENTITY = 'd8ee4aaf-1d8f-46a5-8b7c-7d7e529f5e49';
export const ADMIN__IT_ASSETS = '8be067e1-4c93-4d86-9f86-e2bcbd02d098';
export const ADMIN__SAAS = '4670b699-ba57-49b3-8b69-1100d4bf2c22';
export const ADMIN__CLOUD = '76af9d4e-c36b-42a8-8e03-4e9b609d8296';
export const ADMIN__PLUGINS = 'de17284d-651c-4ffe-82b1-8826552ab8ca';
// #endregion

// #region App Broker
export const IT_REQUESTS = '1bd210da-4453-4615-898b-8704453a0c6e';
export const IT_REQUESTS__PORTAL_MANAGEMENT =
	'55099711-38cd-43d1-8fe5-755089a16466';
export const IT_REQUESTS__PORTAL = '091a9f49-681b-41bf-a955-41ff7dc0dd8e';
// #endregion
