import { t } from 'ttag';

export const getStartedItems = [
	{
		id: 0,
		title: t`Set up your identity provider`,
		description: t`Manage access to Flexera One using your identity provider.`,
		link: 'admin/identity-providers',
		icon: 'IconMdSettings',
		hasPermissionCheck: ['hasIAM', 'canSeeIdentityProviders']
	},
	{
		id: 1,
		title: t`Invite users to Flexera One`,
		description: t`Add your team and assign roles.`,
		link: 'admin/user-management',
		icon: 'IconMdEmail',
		hasPermissionCheck: ['hasIAM', 'canSeeOrgInvitations']
	},
	{
		id: 2,
		title: t`Set up device discovery`,
		description: t`Deploy beacons and start collecting asset data.`,
		link: 'udi/installers',
		icon: 'IconMdHardware',
		hasPermissionCheck: ['hasITVisibility', 'canSeeBeaconsPage']
	},
	{
		id: 3,
		title: t`Explore Automation`,
		description: t`Automate governance across your multi-cloud environment.`,
		link: 'policy/catalog',
		icon: 'IconMdPolicies',
		hasPermissionCheck: ['hasPolicies', 'canSeePolicyCatalog']
	},
	{
		id: 4,
		title: t`Connect your cloud bills`,
		description: t`Start importing bill data from your cloud provider.`,
		link: 'optima/cloud-settings/billing-config',
		icon: 'IconMdCloud',
		hasPermissionCheck: [['hasOptima', 'hasHIF'], 'canSeeOptimaBillingConfig']
	},
	{
		id: 5,
		title: t`Add a SaaS integration`,
		description: `Start tracking utilization of common SaaS applications.`,
		link: 'saas/managed-products/new',
		icon: 'IconMdSaas',
		hasPermissionCheck: ['hasSaasManager', 'canCreateSaasManagedProducts']
	}
];
