const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n > 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n > 1);\n"
        ]
      },
      "Add Dashboard": {
        "msgid": "Add Dashboard",
        "msgstr": [
          "Ajouter un tableau de bord"
        ]
      },
      "All Reports": {
        "msgid": "All Reports",
        "msgstr": [
          "Tous les rapports"
        ]
      },
      "Connections": {
        "msgid": "Connections",
        "msgstr": [
          "Connexions"
        ]
      },
      "Dashboard": {
        "msgid": "Dashboard",
        "msgstr": [
          "Tableau de bord"
        ]
      },
      "Data Explorer": {
        "msgid": "Data Explorer",
        "msgstr": [
          "Explorateur de données"
        ]
      },
      "Data Exports": {
        "msgid": "Data Exports",
        "msgstr": [
          "Exportations de données"
        ]
      },
      "Data Mashup": {
        "msgid": "Data Mashup",
        "msgstr": [
          "Mashup de données"
        ]
      },
      "Devices": {
        "msgid": "Devices",
        "msgstr": [
          "Équipements"
        ]
      },
      "Hardware Evidence": {
        "msgid": "Hardware Evidence",
        "msgstr": [
          "Empreinte du matériel"
        ]
      },
      "Reports": {
        "msgid": "Reports",
        "msgstr": [
          ""
        ]
      },
      "Software Evidence": {
        "msgid": "Software Evidence",
        "msgstr": [
          "Empreinte du logiciel"
        ]
      }
    }
  }
};
export default json;
