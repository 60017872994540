import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const MspCustomers = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/msp.main');
		return { default: (await mod).CustomerConsole };
	}),
	'msp'
);
