const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Jobs": {
        "msgid": "Jobs",
        "msgstr": [
          "Jobs"
        ]
      },
      "Manage Buckets": {
        "msgid": "Manage Buckets",
        "msgstr": [
          "Buckets verwalten"
        ]
      },
      "Manage SBOM Buckets": {
        "msgid": "Manage SBOM Buckets",
        "msgstr": [
          "SBOM-Buckets verwalten"
        ]
      },
      "Manage SBOM Parts": {
        "msgid": "Manage SBOM Parts",
        "msgstr": [
          "SBOM-Teile verwalten"
        ]
      },
      "SBOM Jobs": {
        "msgid": "SBOM Jobs",
        "msgstr": [
          "SBOM-Jobs"
        ]
      },
      "SBOM Preferences": {
        "msgid": "SBOM Preferences",
        "msgstr": [
          "SBOM-Einstellungen"
        ]
      }
    }
  }
};
export default json;
