import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { useSaTechnologySpendHide } from '@flexera/flexera-one.feature-flags';
import {
	SpendDashboard,
	SpendDataMashup,
	SpendPowerBiDashboard
} from './UnifiedSpendApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();
	const hasSaTechnologySpendHide = useSaTechnologySpendHide();

	useEffect(() => {
		const legacyTechSpendRoutes = [];
		const techSpendPBIRoutes = [];

		techSpendPBIRoutes.push({
			id: `sa.bootstrap/powerbi-dashboard-sa-embed`,
			parentId: OrgsRouteId,
			path: `/sa/:pageId/:categoryId/:dashboard_name`,
			exact: true,
			component: SpendPowerBiDashboard
		});

		if (!hasSaTechnologySpendHide) {
			legacyTechSpendRoutes.push(
				{
					id: 'spend.bootstrap/dashboard',
					parentId: OrgsRouteId,
					path: '/spend/analytics/:dashboardId?',
					exact: false,
					component: SpendDashboard
				},
				{
					id: 'spend.bootstrap/add-custom-dashboard',
					parentId: OrgsRouteId,
					path: '/spend/analytics/add',
					exact: false,
					component: SpendDashboard
				},
				{
					id: 'spend.bootstrap/explorer-custom-dashboard',
					parentId: OrgsRouteId,
					path: '/spend/analytics/explorer',
					exact: false,
					component: SpendDashboard
				},
				{
					id: 'spend.bootstrap/data-mashup',
					parentId: OrgsRouteId,
					path: '/spend/data-mashup',
					exact: false,
					component: SpendDataMashup
				}
			);
		}

		return addRoutes([...techSpendPBIRoutes, ...legacyTechSpendRoutes]);
	}, [hasSaTechnologySpendHide]);
}
