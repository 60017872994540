import { useAuthenticated } from '@flexera/auth.base';
import { useMspCustomersAndNavShow } from '@flexera/flexera-one.feature-flags';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { MSP } from '@flexera/shell.base-nav-tree';
import {
	loadingItem,
	MenuItemDef,
	useNavItems
} from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();

	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const [perms, loading] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.hasMSP,
		Permissions.canCreateMspCustomer,
		Permissions.canDeleteMspCustomer,
		Permissions.canListMspCustomer,
		Permissions.canSeeMspCustomerDetails,
		Permissions.canUpdateMspCustomer
	);
	const mspCustomersAndNavShow = useMspCustomersAndNavShow();

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasMSP)) return;

		const canListCustomers =
			perms.get(Permissions.hasMSP) &&
			perms.get(Permissions.canListMspCustomer) &&
			mspCustomersAndNavShow;
		const canSeeMspCustomerDetails =
			perms.get(Permissions.hasMSP) &&
			perms.get(Permissions.canSeeMspCustomerDetails) &&
			mspCustomersAndNavShow;

		const mspMenuItems: MenuItemDef[] = [
			canListCustomers && {
				id: ids.CUSTOMERS,
				parentId: MSP,
				helpHref: `${helpDocBaseUrl}/Partners/ManageCustomers.htm`,
				path: `/orgs/${orgId}/customers`,
				urlMatch: /^\/orgs\/\d+\/customers/,
				label: t`Customer`,
				priority: 30,
				hidden: true
			},
			canSeeMspCustomerDetails && {
				id: ids.CUSTOMERSDETAIL,
				parentId: ids.CUSTOMERS,
				helpHref: `${helpDocBaseUrl}/Partners/ManageCustomers.htm`,
				path: `/orgs/${orgId}/customers/:customerOrgId`,
				urlMatch: /^\/orgs\/\d+\/customers\/\d+/,
				variableTitle: true,
				priority: 30,
				hidden: true
			}
		];

		return addItems([...mspMenuItems].filter(Boolean));
	}, [org, orgId, authenticated, loading, perms]);
}
