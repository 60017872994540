import { enterKey } from '../src/constants';

export const toggleEnterKey = (event, callback, parameters = null) => {
	// Toggle the callback when RETURN is pressed
	if (event.keyCode && event.keyCode === enterKey) {
		callback(parameters);
	}
};

export const handleKeyboardAccess = (
	event: React.KeyboardEvent<HTMLLIElement>,
	action: (val?: unknown) => void,
	value?: unknown
) => {
	if (event.key === 'Enter') {
		if (value) {
			action(value);
			return;
		}
		action();
	}
};
