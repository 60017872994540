import { withCapability } from '@flexera/lib.permissions';
import { lazyLoaded } from '@flexera/lib.utilities';

export const SpendDashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/spend.analytics');
		return { default: (await mod).DashboardPage };
	}),
	'sa'
);

export const SpendDataMashup = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/spend.analytics');
		return { default: (await mod).DataMashupPage };
	}),
	'sa'
);

export const SpendPowerBiDashboard = withCapability(
	lazyLoaded(async () => {
		const mod = import('@flexera/spend.analytics');
		return { default: (await mod).SpendPowerBiDashboard };
	}),
	'sa'
);
