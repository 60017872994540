import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { MspCustomers } from './MspApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	const mspRoutes = [
		{
			id: 'msp.bootstrap/customers',
			parentId: OrgsRouteId,
			path: '/customers/:customerOrgId?/:subTabName?',
			exact: false,
			component: MspCustomers
		}
	];

	useEffect(() => {
		return addRoutes(mspRoutes);
	}, []);
}
