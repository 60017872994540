import { useAuthenticated } from '@flexera/auth.base';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import {
	DASHBOARDS,
	DATA_COLLECTION__SPEND__ANALYTICS,
	SPEND_ANALYTICS,
	DASHBOARDS__TI,
	IT_VISIBILITY__TI
} from '@flexera/shell.base-nav-tree';
import {
	OOTB_DASHBOARD_PAGE_ID,
	OOTB_ITV_TI_PAGE_ID,
	getHiddenOotbRoutes
} from '@flexera/platform.main';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import { helpDocBaseUrl } from '@flexera/lib.utilities';
import { useSaTechnologySpendHide } from '@flexera/flexera-one.feature-flags';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const hasSaTechnologySpendHide = useSaTechnologySpendHide();
	// Permissions
	const [perms, loading] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canSeeSpendAnalyticsDashboard,
		Permissions.hasSpendAnalytics,
		Permissions.canUploadSpendAnalyticsData,
		Permissions.canViewSAReports
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		if (!perms.get(Permissions.hasSpendAnalytics)) return;

		const items = [];

		if (
			perms.get(Permissions.canSeeSpendAnalyticsDashboard) &&
			!hasSaTechnologySpendHide
		) {
			items.push({
				id: ids.ANALYTICS,
				parentId: DASHBOARDS,
				path: `/orgs/${orgId}/spend/analytics`,
				helpHref: `${helpDocBaseUrl}/TechnologySpend/spenddata.htm`,
				urlMatch: /^\/orgs\/\d+\/spend\/analytics(\/|$|\?)/,
				label: t`Technology Spend`,
				priority: 10
			});
		}

		if (
			perms.get(Permissions.canUploadSpendAnalyticsData) &&
			!hasSaTechnologySpendHide
		) {
			items.push({
				id: ids.DATA_MASHUP,
				parentId: DATA_COLLECTION__SPEND__ANALYTICS,
				path: `/orgs/${orgId}/spend/data-mashup`,
				urlMatch: /^\/orgs\/\d+\/spend\/data-mashup(\/|$|\?)/,
				label: t`Data Mashup`
			});
		}

		// Dashboards -> Technology Information OOTB Report --> Technology Spend
		if (perms.get(Permissions.canViewSAReports)) {
			const hiddenDashboardRoutes = getHiddenOotbRoutes({
				routeId: `${SPEND_ANALYTICS}-dashboards`,
				capability: 'sa',
				orgId,
				parentId: DASHBOARDS__TI,
				pageId: OOTB_DASHBOARD_PAGE_ID,
				helpHref: `${helpDocBaseUrl}/ITVisibility/OOTBReportDescriptions.htm`
			});

			items.push(...hiddenDashboardRoutes);
		}

		if (perms.get(Permissions.canViewSAReports)) {
			// IT Visibility -> Technology Information OOTB Report -> Tech Spend
			const hiddenDashboardRoutes = getHiddenOotbRoutes({
				routeId: ids.POWERBIDASHBOARD,
				capability: 'sa',
				orgId,
				parentId: IT_VISIBILITY__TI,
				pageId: OOTB_ITV_TI_PAGE_ID,
				helpHref: `${helpDocBaseUrl}/ITVisibility/OOTBReportDescriptions.htm`
			});

			items.push(...hiddenDashboardRoutes);
		}

		return addItems(items);
	}, [org, orgId, authenticated, perms, loading, hasSaTechnologySpendHide]);
}
