const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Clear the branch and go back to the production build?": {
        "msgid": "Clear the branch and go back to the production build?",
        "comments": {
          "extracted": "eslint-disable-next-line no-alert"
        },
        "msgstr": [
          "Clear the branch and go back to the production build?"
        ]
      },
      "Error...": {
        "msgid": "Error...",
        "msgstr": [
          "Error..."
        ]
      },
      "Get help in Community": {
        "msgid": "Get help in Community",
        "msgstr": [
          "Get help in Community"
        ]
      },
      "Loading...": {
        "msgid": "Loading...",
        "msgstr": [
          "Loading..."
        ]
      },
      "Log out": {
        "msgid": "Log out",
        "msgstr": [
          "Log out"
        ]
      },
      "Press help icon to show all options.": {
        "msgid": "Press help icon to show all options.",
        "msgstr": [
          "Press help icon to show all options."
        ]
      },
      "Press here to Get help in Community and discuss this feature on the Flexera Community.": {
        "msgid": "Press here to Get help in Community and discuss this feature on the Flexera Community.",
        "msgstr": [
          "Press here to Get help in Community and discuss this feature on the Flexera Community."
        ]
      },
      "Press here to view documentation with detailed feature on docs.flexera.com.": {
        "msgid": "Press here to view documentation with detailed feature on docs.flexera.com.",
        "msgstr": [
          "Press here to view documentation with detailed feature on docs.flexera.com."
        ]
      },
      "Privacy Policy": {
        "msgid": "Privacy Policy",
        "msgstr": [
          "Privacy Policy"
        ]
      },
      "Profile details": {
        "msgid": "Profile details",
        "msgstr": [
          "Profile details"
        ]
      },
      "Select an Organization": {
        "msgid": "Select an Organization",
        "msgstr": [
          "Select an Organization"
        ]
      },
      "Selected Organization ${ 0 }": {
        "msgid": "Selected Organization ${ 0 }",
        "comments": {
          "flag": "javascript-format"
        },
        "msgstr": [
          "Selected Organization ${ 0 }"
        ]
      },
      "User Settings": {
        "msgid": "User Settings",
        "msgstr": [
          "User Settings"
        ]
      },
      "View Documentation": {
        "msgid": "View Documentation",
        "msgstr": [
          "View Documentation"
        ]
      },
      "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?": {
        "msgid": "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?",
        "comments": {
          "extracted": "eslint-disable-next-line no-alert",
          "flag": "javascript-format"
        },
        "msgstr": [
          "You are running a white-labelled versioned of Flexera One within the scope of the ${ 0 } partner. Do you want to clear this setting and return to the main production build?"
        ]
      }
    }
  }
};
export default json;
