const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n!=1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n!=1);\n"
        ]
      },
      "Begin Searching": {
        "msgid": "Begin Searching",
        "msgstr": [
          "Begin Searching"
        ]
      },
      "Cancel": {
        "msgid": "Cancel",
        "msgstr": [
          "Cancel"
        ]
      },
      "Clear your search": {
        "msgid": "Clear your search",
        "msgstr": [
          "Clear your search"
        ]
      },
      "Navigation Search": {
        "msgid": "Navigation Search",
        "msgstr": [
          "Navigation Search"
        ]
      },
      "No favorites to display.": {
        "msgid": "No favorites to display.",
        "msgstr": [
          "No favorites to display."
        ]
      },
      "No recent searches": {
        "msgid": "No recent searches",
        "msgstr": [
          "No recent searches"
        ]
      },
      "No results for this search": {
        "msgid": "No results for this search",
        "msgstr": [
          "No results for this search"
        ]
      },
      "Press here to cancel the navigation search and see the navigation menu list.": {
        "msgid": "Press here to cancel the navigation search and see the navigation menu list.",
        "msgstr": [
          "Press here to cancel the navigation search and see the navigation menu list."
        ]
      },
      "Press here to make ${ 0 } in favorite list.": {
        "msgid": "Press here to make ${ 0 } in favorite list.",
        "msgstr": [
          "Press here to make ${ 0 } in favorite list."
        ]
      },
      "Press here to remove ${ 0 } from favorite list.": {
        "msgid": "Press here to remove ${ 0 } from favorite list.",
        "comments": {
          "flag": "javascript-format"
        },
        "msgstr": [
          "Press here to remove ${ 0 } from favorite list."
        ]
      },
      "Search": {
        "msgid": "Search",
        "msgstr": [
          "Search"
        ]
      },
      "Your recent search terms...": {
        "msgid": "Your recent search terms...",
        "msgstr": [
          "Your recent search terms..."
        ]
      },
      "get search results for recent search term:": {
        "msgid": "get search results for recent search term:",
        "msgstr": [
          "get search results for recent search term:"
        ]
      }
    }
  }
};
export default json;
