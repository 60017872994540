import React, { useState } from 'react';
import { flushSync } from 'react-dom';
import { NavItem } from '@flexera/shell.navigation';
import { MenuItem } from '../MenuItem/MenuItem';
import { MenuContentStyled } from './MenuContent.styled';

interface MenuProps {
	items: NavItem[];
	searchWord?: string;
}

export const MenuContent = ({ items, searchWord }: MenuProps) => {
	const [flyoutOpen, setFlyoutOpen] = useState(false);
	let menuContentTimer = null;
	let flyoutLeaveTimer = null;

	const handleMouseOver = (
		event: React.MouseEvent<HTMLUListElement, MouseEvent>
	) => {
		clearTimeout(flyoutLeaveTimer);
		if (event.target.classList.contains('navlink')) {
			flushSync(() => {
				setFlyoutOpen(false);
			});
			return;
		}
		if (!flyoutOpen) {
			menuContentTimer = setTimeout(() => {
				flushSync(() => {
					setFlyoutOpen(true);
				});
			}, 400);
		}
	};

	const handleFlyoutLeave = () => {
		flyoutLeaveTimer = setTimeout(() => {
			flushSync(() => {
				setFlyoutOpen(false);
			});
		}, 50);
	};

	const handleContentMouseLeave = () => {
		clearTimeout(menuContentTimer);
		flushSync(() => {
			setFlyoutOpen(false);
		});
	};
	return (
		<MenuContentStyled
			role={'menubar'}
			tabIndex={-1}
			onMouseOver={handleMouseOver}
			onMouseLeave={() => handleContentMouseLeave()}
			flyoutOpen={flyoutOpen}
		>
			{items.map((item, index) => (
				<MenuItem
					index={index}
					item={item}
					key={item.id ? item.id : `${item.label}${index}`}
					searchWord={searchWord}
					setFlyoutOpen={(isOpen) => {
						flushSync(() => {
							setFlyoutOpen(isOpen);
						});
					}}
					handleFlyoutLeave={handleFlyoutLeave}
				/>
			))}
		</MenuContentStyled>
	);
};
