const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Adjustment Program": {
        "msgid": "Adjustment Program",
        "msgstr": [
          "Anpassungsprogramm"
        ]
      },
      "Billing Centers": {
        "msgid": "Billing Centers",
        "msgstr": [
          "Kostenstellen"
        ]
      },
      "Billing Configuration": {
        "msgid": "Billing Configuration",
        "msgstr": [
          "Konfiguration der Rechnungsstellung"
        ]
      },
      "Budgets": {
        "msgid": "Budgets",
        "msgstr": [
          "Budgets"
        ]
      },
      "Cloud": {
        "msgid": "Cloud",
        "msgstr": [
          "Cloud"
        ]
      },
      "Cloud Cost Anomalies": {
        "msgid": "Cloud Cost Anomalies",
        "msgstr": [
          "Kostenanomalien der Cloud"
        ]
      },
      "Cloud Migration Planning": {
        "msgid": "Cloud Migration Planning",
        "msgstr": [
          "Planung der Cloud-Migration"
        ]
      },
      "Cost Planning": {
        "msgid": "Cost Planning",
        "msgstr": [
          "Kostenplanung"
        ]
      },
      "Create Recommendation Sets": {
        "msgid": "Create Recommendation Sets",
        "msgstr": [
          "Empfehlungssätze erstellen"
        ]
      },
      "Edit Recommendation Sets": {
        "msgid": "Edit Recommendation Sets",
        "msgstr": [
          "Empfehlungssätze bearbeiten"
        ]
      },
      "Optimization": {
        "msgid": "Optimization",
        "msgstr": [
          "Optimierung"
        ]
      },
      "Recommendation Sets": {
        "msgid": "Recommendation Sets",
        "msgstr": [
          "Empfehlungssätze"
        ]
      },
      "Reserved Instances": {
        "msgid": "Reserved Instances",
        "msgstr": [
          "Reservierte Instanzen"
        ]
      },
      "Rule-Based Dimensions": {
        "msgid": "Rule-Based Dimensions",
        "msgstr": [
          "Regelbasierte Dimensionen"
        ]
      },
      "Savings Plans": {
        "msgid": "Savings Plans",
        "msgstr": [
          "Sparpläne"
        ]
      },
      "Tabular View": {
        "msgid": "Tabular View",
        "msgstr": [
          "Tabellarische Ansicht"
        ]
      },
      "Tag Dimensions": {
        "msgid": "Tag Dimensions",
        "msgstr": [
          "Tag-Dimensionen"
        ]
      },
      "Total Potential Savings": {
        "msgid": "Total Potential Savings",
        "msgstr": [
          "Gesamteinsparpotenzial"
        ]
      }
    }
  }
};
export default json;
