const json = {
  "charset": "utf-8",
  "headers": {
    "Content-Type": "text/plain; charset=UTF-8",
    "Plural-Forms": "nplurals=2; plural=(n != 1);"
  },
  "translations": {
    "": {
      "": {
        "msgid": "",
        "msgstr": [
          "Content-Type: text/plain; charset=UTF-8\nPlural-Forms: nplurals=2; plural=(n != 1);\n"
        ]
      },
      "Begin Searching": {
        "msgid": "Begin Searching",
        "msgstr": [
          "Suche beginnen"
        ]
      },
      "Cancel": {
        "msgid": "Cancel",
        "msgstr": [
          "Abbrechen"
        ]
      },
      "Clear your search": {
        "msgid": "Clear your search",
        "msgstr": [
          "Löschen Sie Ihre Suche"
        ]
      },
      "Navigation Search": {
        "msgid": "Navigation Search",
        "msgstr": [
          "Navigationssuche"
        ]
      },
      "No favorites to display.": {
        "msgid": "No favorites to display.",
        "msgstr": [
          "Es gibt keine Favoriten zum Anzeigen."
        ]
      },
      "No recent searches": {
        "msgid": "No recent searches",
        "msgstr": [
          "Keine kürzlichen Suchanfragen"
        ]
      },
      "No results for this search": {
        "msgid": "No results for this search",
        "msgstr": [
          "Keine Ergebnisse für diese Suche"
        ]
      },
      "Press here to cancel the navigation search and see the navigation menu list.": {
        "msgid": "Press here to cancel the navigation search and see the navigation menu list.",
        "msgstr": [
          "Hier klicken, um die Navigationssuche abzubrechen und die Liste der Navigationsmenüs anzuzeigen."
        ]
      },
      "Press here to make ${ 0 } in favorite list.": {
        "msgid": "Press here to make ${ 0 } in favorite list.",
        "msgstr": [
          "Zum Aufnehmen von ${ 0 } in die Favoritenliste hier klicken."
        ]
      },
      "Press here to remove ${ 0 } from favorite list.": {
        "msgid": "Press here to remove ${ 0 } from favorite list.",
        "msgstr": [
          "Zum Entfernen von ${ 0 } aus der Favoritenliste hier klicken."
        ]
      },
      "Search": {
        "msgid": "Search",
        "msgstr": [
          "Suchen"
        ]
      },
      "Your recent search terms...": {
        "msgid": "Your recent search terms...",
        "msgstr": [
          "Ihre letzten Suchbegriffe..."
        ]
      },
      "get search results for recent search term:": {
        "msgid": "get search results for recent search term:",
        "msgstr": [
          "Suchergebnisse für den letzten Suchbegriff abrufen:"
        ]
      }
    }
  }
};
export default json;
