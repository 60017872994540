import { useRoutes as useRoutesHook } from '@flexera/lib.router';
import { OrgsRouteId } from '@flexera/shell.orgs-routes';
import { useEffect } from 'react';
import { HIFPowerBiDashboard } from './HIFApp';

export function useRoutes() {
	const { addRoutes } = useRoutesHook();

	useEffect(() => {
		return addRoutes([
			{
				id: `hif.bootstrap/powerbi-dashboard-hif-embed`,
				parentId: OrgsRouteId,
				path: `/hif/:pageId/:categoryId/:dashboard_name`,
				exact: true,
				component: HIFPowerBiDashboard
			},
			// to be removed
			{
				id: 'hif.bootstrap/powerbi-dashboard-hif-embed-legacy',
				parentId: OrgsRouteId,
				path: '/hif/report/:dashboard_name?',
				exact: true,
				component: HIFPowerBiDashboard
			},
			{
				id:
					'hif.bootstrap/powerbi-software-insights-and-spend-optimization-hif-embed',
				parentId: OrgsRouteId,
				path: '/hif/:dashboard_name?',
				exact: true,
				component: HIFPowerBiDashboard
			}
		]);
	}, []);
}
